import { Link } from "react-router-dom";
import Accordion from "./Accordion";

import data from "./faqdata";

const Faq = () => {
  return (
    <section
      className="flex-col px-4 bg-lightGreen py-20 mt-8 lg:mt-24 lg:px-28 2xl:px-96 flex lg:flex-row justify-between"
      id="faq"
    >
      <section className="w-full lg:w-96">
        <h1 className="bold-font text-xl lg:text-3xl">
          All your questions answered...
        </h1>
        <p className="regular-font text-sm lg:text-sm pt-6">
        We’re glad to answer all your questions here or on our social media platforms.
          { " "}
          <a
            href="mailto:support@buymejollof.com"
            className="text-groomlyGreen underline text-"
          >
            Reach out
          </a>{" "}
          to us if you have any more questions, or reach out to us via social
          media
        </p>
        <section className="flex items-center gap-5 pt-8">
          <Link to="https://www.instagram.com/buymejollof">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 9.14874C10.7797 9.14874 9.37577 10.5527 9.37577 12.273C9.37577 13.9933 10.7797 15.3972 12.5 15.3972C14.2203 15.3972 15.6242 13.9933 15.6242 12.273C15.6242 10.5527 14.2203 9.14874 12.5 9.14874ZM21.8703 12.273C21.8703 10.9792 21.882 9.69718 21.8094 8.40578C21.7367 6.90578 21.3945 5.57453 20.2976 4.47765C19.1984 3.37843 17.8695 3.03859 16.3695 2.96593C15.0758 2.89328 13.7937 2.90499 12.5023 2.90499C11.2086 2.90499 9.92655 2.89328 8.63515 2.96593C7.13515 3.03859 5.8039 3.38078 4.70702 4.47765C3.6078 5.57687 3.26796 6.90578 3.1953 8.40578C3.12265 9.69953 3.13436 10.9816 3.13436 12.273C3.13436 13.5644 3.12265 14.8487 3.1953 16.1402C3.26796 17.6402 3.61015 18.9714 4.70702 20.0683C5.80624 21.1675 7.13515 21.5073 8.63515 21.58C9.9289 21.6526 11.2109 21.6409 12.5023 21.6409C13.7961 21.6409 15.0781 21.6526 16.3695 21.58C17.8695 21.5073 19.2008 21.1651 20.2976 20.0683C21.3969 18.9691 21.7367 17.6402 21.8094 16.1402C21.8844 14.8487 21.8703 13.5667 21.8703 12.273V12.273ZM12.5 17.08C9.83983 17.08 7.69296 14.9331 7.69296 12.273C7.69296 9.61281 9.83983 7.46593 12.5 7.46593C15.1601 7.46593 17.307 9.61281 17.307 12.273C17.307 14.9331 15.1601 17.08 12.5 17.08ZM17.5039 8.39171C16.8828 8.39171 16.3812 7.89015 16.3812 7.26906C16.3812 6.64796 16.8828 6.1464 17.5039 6.1464C18.125 6.1464 18.6266 6.64796 18.6266 7.26906C18.6267 7.41654 18.5978 7.56261 18.5415 7.6989C18.4851 7.83519 18.4024 7.95902 18.2981 8.06331C18.1939 8.16759 18.07 8.25028 17.9337 8.30663C17.7974 8.36299 17.6514 8.3919 17.5039 8.39171V8.39171Z"
                fill="#2A2A2A"
              />
            </svg>
          </Link>
          <Link to="https://www.twitter.com/buymejollof">
            <svg
              width="21"
              height="17"
              viewBox="0 0 21 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.133 4.26999C18.146 4.44499 18.146 4.61899 18.146 4.79299C18.146 10.118 14.093 16.254 6.686 16.254C4.404 16.254 2.284 15.593 0.5 14.445C0.824 14.482 1.136 14.495 1.473 14.495C3.28599 14.4994 5.04765 13.8931 6.474 12.774C5.63342 12.7588 4.81858 12.4814 4.14324 11.9807C3.4679 11.48 2.96578 10.7809 2.707 9.98099C2.956 10.018 3.206 10.043 3.468 10.043C3.829 10.043 4.192 9.99299 4.529 9.90599C3.61676 9.72181 2.79647 9.22734 2.20762 8.50668C1.61876 7.78601 1.29769 6.88364 1.299 5.95299V5.90299C1.836 6.20199 2.459 6.38899 3.119 6.41399C2.56609 6.04658 2.11272 5.54802 1.79934 4.96278C1.48596 4.37755 1.32231 3.72385 1.323 3.05999C1.323 2.31199 1.522 1.62599 1.871 1.02799C2.88314 2.27301 4.1455 3.29155 5.57634 4.01765C7.00717 4.74375 8.57456 5.16121 10.177 5.24299C10.115 4.94299 10.077 4.63199 10.077 4.31999C10.0767 3.79095 10.1807 3.26705 10.3831 2.77823C10.5854 2.28941 10.8821 1.84527 11.2562 1.47118C11.6303 1.09709 12.0744 0.800403 12.5632 0.598071C13.0521 0.395738 13.576 0.29173 14.105 0.291993C15.265 0.291993 16.312 0.777992 17.048 1.56399C17.9498 1.38961 18.8145 1.06041 19.604 0.590992C19.3034 1.52181 18.6738 2.3111 17.833 2.81099C18.6328 2.71977 19.4144 2.50945 20.152 2.18699C19.6011 2.99009 18.9185 3.69434 18.133 4.26999V4.26999Z"
                fill="#2A2A2A"
              />
            </svg>
          </Link>
          <Link to="https://www.youtube.com/buymejollof">
            <svg
              width="25"
              height="17"
              viewBox="0 0 25 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24 2.78003C23.8641 2.29526 23.5994 1.85637 23.234 1.51003C22.8583 1.15303 22.3978 0.897661 21.896 0.768025C20.018 0.273025 12.494 0.273025 12.494 0.273025C9.35734 0.237338 6.22144 0.3943 3.10401 0.743025C2.6022 0.882238 2.14257 1.14331 1.76601 1.50303C1.39601 1.85903 1.12801 2.29803 0.988007 2.77903C0.6517 4.59077 0.488299 6.43037 0.500007 8.27303C0.488007 10.114 0.651007 11.953 0.988007 13.767C1.12501 14.246 1.39201 14.683 1.76301 15.036C2.13401 15.389 2.59601 15.644 3.10401 15.779C5.00701 16.273 12.494 16.273 12.494 16.273C15.6347 16.3088 18.7746 16.1518 21.896 15.803C22.3978 15.6734 22.8583 15.418 23.234 15.061C23.5993 14.7147 23.8637 14.2758 23.999 13.791C24.3441 11.98 24.5119 10.1396 24.5 8.29603C24.526 6.44463 24.3584 4.59559 24 2.77903V2.78003ZM10.102 11.697V4.85003L16.362 8.27403L10.102 11.697Z"
                fill="#2A2A2A"
              />
            </svg>
          </Link>
        </section>
      </section>
      <section className="w-full pt-8 lg:pt-0 lg:w-3/6">
        {data?.map((datum: any) => {
          return <Accordion datum={datum} key={datum.id} />;
        })}
      </section>
    </section>
  );
};

export default Faq;
