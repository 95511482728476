import clsx from "clsx";

const IntroWidget = ({ data }: { data: any }) => {
  return (
    <section
      className={clsx(
        "w-full md:w-[325px] h-[480px] md:h-[570px] md:max-h-[570px] rounded-2xl"
      )}
    >
      <section className="relative flex w-full h-full items-center flex-col justify-center">
        <section className="w-full relative h-full">
          <div className="w-full relative h-full rounded-lg">
            <video
              controls
              autoPlay={false}
              className="rounded-2xl w-full h-full md:h-[580px] md:max-h-[580px] object-cover"
            >
              <source src={data?.jollofPage?.introVideo?.url} />
            </video>
            <section
              className="flex justify-end absolute rounded-tl-2xl rounded-tr-2xl top-0 right-0 w-full h-12 px-2 py-2 gap-2"
              style={{
                background:
                  "linear-gradient(rgb(0 0 0 / 100%), rgb(255 255 255 / 0%))",
              }}
            ></section>
          </div>
        </section>
      </section>
    </section>
  );
};

export default IntroWidget;
