export const WishlistEmptyImage = () => {
  return (
    <svg
      width="127"
      height="147"
      viewBox="0 0 127 147"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_4878_5473)">
        <rect
          x="23.9883"
          y="115.64"
          width="79.3642"
          height="7.68041"
          fill="#AAAAAA"
        />
        <rect
          x="23.9883"
          y="115.64"
          width="79.3642"
          height="7.68041"
          stroke="#C3C3C3"
        />
      </g>
      <rect
        x="24.6465"
        y="10.6738"
        width="79.3642"
        height="50.5627"
        fill="#E0E5EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6366 10.6738V87.4779H11.8359V41.0349L18.2373 31.2102L24.6366 10.6738Z"
        fill="#CFD4DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.365 10.6738V87.4779H116.166V41.0349L109.765 31.2102L103.365 10.6738Z"
        fill="#CFD4DC"
      />
      <path
        d="M11.8359 40.7559H116.161V126.152C116.161 128.83 113.991 131.001 111.313 131.001H16.6844C14.0067 131.001 11.8359 128.83 11.8359 126.152V40.7559Z"
        fill="white"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.1875 76.3156C43.1875 75.8677 43.4175 75.4512 43.8155 75.2456C44.7011 74.7882 46.3932 74.0449 48.0375 74.0449C49.6813 74.0449 51.3251 74.7877 52.1816 75.2451C52.5675 75.4513 52.788 75.8596 52.788 76.2971C52.788 77.3277 51.6012 78.0033 50.6315 77.6542C49.8526 77.3738 48.9367 77.1467 48.0133 77.1467C47.0717 77.1467 46.13 77.3829 45.3318 77.6709C44.364 78.0201 43.1875 77.3445 43.1875 76.3156Z"
        fill="#AAAAAA"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.8262 76.3156C75.8262 75.8677 76.0562 75.4512 76.4541 75.2456C77.3398 74.7882 79.0318 74.0449 80.6762 74.0449C82.32 74.0449 83.9637 74.7877 84.8203 75.2451C85.2062 75.4513 85.4267 75.8596 85.4267 76.2971C85.4267 77.3277 84.2399 78.0033 83.2702 77.6542C82.4913 77.3738 81.5754 77.1467 80.652 77.1467C79.7104 77.1467 78.7687 77.3829 77.9705 77.6709C77.0026 78.0201 75.8262 77.3445 75.8262 76.3156Z"
        fill="#AAAAAA"
      />
      <ellipse
        opacity="0.5"
        cx="64.305"
        cy="89.4099"
        rx="10.2405"
        ry="4.48218"
        fill="#AAAAAA"
      />
      <circle
        opacity="0.149343"
        cx="40.6312"
        cy="50.3646"
        r="3.8402"
        fill="#AAAAAA"
      />
      <circle
        opacity="0.149343"
        cx="87.3636"
        cy="50.3646"
        r="3.8402"
        fill="#AAAAAA"
      />
      <path
        d="M40.6465 50.1658V26.3612C40.6465 13.4592 51.1057 3 64.0077 3C76.9098 3 87.369 13.4592 87.369 26.3612V50.1658"
        stroke="#C3C3C3"
        strokeWidth="4.8485"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_f_4878_5473"
          x="0.534193"
          y="92.1856"
          width="126.271"
          height="54.5888"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="11.727"
            result="effect1_foregroundBlur_4878_5473"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const BankEmptyStateImage = () => {
  return (
    <svg
      width="220"
      height="102"
      viewBox="0 0 220 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="9.42368"
        cy="51.4227"
        r="8.75145"
        fill="#F1F1F1"
        stroke="black"
        strokeWidth="0.646219"
      />
      <rect
        x="4.13037"
        y="6.0498"
        width="160.317"
        height="18.1491"
        rx="7.75463"
        fill="#F1F1F1"
      />
      <rect
        x="59.3335"
        y="42.3481"
        width="160.317"
        height="18.1491"
        rx="7.75463"
        fill="#F1F1F1"
      />
      <rect
        x="29.085"
        y="79.4023"
        width="110.407"
        height="18.1491"
        rx="7.75463"
        fill="#F1F1F1"
      />
      <rect
        x="5.29257"
        y="22.7968"
        width="96.369"
        height="63.0957"
        rx="4.20043"
        transform="rotate(-12.9733 5.29257 22.7968)"
        fill="#F3F3F3"
        stroke="#999999"
        strokeWidth="1.93866"
      />
      <rect
        x="56.5221"
        y="37.2677"
        width="96.369"
        height="63.0957"
        rx="4.20043"
        fill="#F3F3F3"
        stroke="#999999"
        strokeWidth="1.93866"
      />
      <rect
        x="57.8213"
        y="53.6909"
        width="93.7704"
        height="13.6118"
        fill="#DDDDDD"
      />
      <path
        d="M57.8213 53.6909H93.3633M96.7663 53.6909H105.463M108.866 53.6909H143.651M151.214 67.3028H126.259M117.562 67.3028H74.458"
        stroke="#999999"
        strokeWidth="1.93866"
        strokeLinecap="round"
      />
      <circle
        cx="173.522"
        cy="88.4769"
        r="8.75145"
        fill="#F1F1F1"
        stroke="black"
        strokeWidth="0.646219"
      />
      <circle cx="191.671" cy="15.1244" r="9.07456" fill="#F1F1F1" />
    </svg>
  );
};
