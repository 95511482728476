/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CancelButton,
  Whatsapp,
  Facebook,
  Twitter,
  Telegram,
  Copy,
} from "../assets";

import emoji from "../assets/emoji.png";

interface modalProps {
  open: boolean;

  setOpen: any;
}
export default function ReUseModal({ open, setOpen }: modalProps) {
  const [copied, setCopied] = useState(false);
  const urlWebsite = "https://buymejollof.com";
  
  const copyToClipboard = (url: string) => {
    navigator.clipboard.writeText(url);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity backdrop-blur-md" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-[40px]   text-left overflow-hidden shadow-xl transform transition-all  sm:align-middle sm:max-w-xl sm:w-full  ">
              <div className="modal-grad h-[224px] w-[1063px]  absolute top-0 left-[-50%] right-0 z-0"></div>
              <div className="w-full flex flex-col  relative min-h-[600px] sm:p-8 p-4">
                <div className="flex justify-end mt-2">
                  <span
                    className="w-auto cursor-pointer"
                    onClick={() => setOpen(false)}
                  >
                    <CancelButton />
                  </span>
                </div>
                <div className="flex justify-center items-center mt-4">
                  <img src={emoji} alt="emoji" className="w-auto h-auto" />
                </div>
                <div className="w-full   flex flex-col items-center mx-auto mt-8">
                  <h1 className="text-black lg:text-[32px] font-bold sm:text-[28px] text-2xl text-center title-font-su">
                    Yo Superstar! You have saved your spot successfully! 😋
                  </h1>
                  <p className="title-font-su text-sm sm:text-xl text-[#595959] w-full mx-auto mt-3 sm:w-10/12 text-center">
                    Share your link with your friends. Invite them to
                    Buymejollof too!
                  </p>
                </div>
                <div className="w-full flex flex-col mt-8">
                  <h1 className="text-black lg:text-2xl font-extrabold sm:text-xl text-lg  title-font-su mb-6">
                    Share.
                  </h1>
                  <div className="grid grid-cols-3 lg:grid-cols-6 gap-[37px]">
                    <a
                      href="https://twitter.com/intent/tweet?url=https://buymejollof.com"
                      className="w-full flex flex-col items-center"
                    >
                      <div className="w-20 h-20 rounded-full flex justify-center items-center bg-[#f6f6f6] cursor-pointer outline-none border-none mx-auto">
                        <Twitter />
                      </div>
                      <p className="regular-font text-[#595959] text-sm mt-4 text-center mx-auto ">
                        Twitter
                      </p>
                    </a>
                    {/* end of a session */}
                    {/* start of a session */}
                    <div className="w-full flex flex-col items-center">
                      <a
                        href="https://www.facebook.com/sharer/sharer.php?u=https://buymejollof.com"
                        className="w-20 h-20 rounded-full flex justify-center items-center bg-[#f6f6f6] cursor-pointer outline-none border-none mx-auto"
                      >
                        <Facebook />
                      </a>
                      <p className="regular-font text-[#595959] text-sm mt-4 text-center mx-auto ">
                        Facebook
                      </p>
                    </div>
                    <div className="w-full flex flex-col items-center">
                      <a
                        href="https://telegram.me/share/url?url=https://buymejollof.com"
                        className="w-20 h-20 rounded-full flex justify-center items-center bg-[#f6f6f6] cursor-pointer outline-none border-none mx-auto"
                      >
                        <Telegram />
                      </a>
                      <p className="regular-font text-[#595959] text-sm mt-4 text-center mx-auto ">
                        Telegram
                      </p>
                    </div>
                    {/* end of a session */}
                    {/* start of a session */}
                    <div className="w-full flex flex-col items-center">
                      <a
                        href="https://telegram.me/share/url?url=https://buymejollof.com"
                        className="w-20 h-20 rounded-full flex justify-center items-center bg-[#f6f6f6] cursor-pointer outline-none border-none mx-auto"
                      >
                        <Whatsapp />
                      </a>
                      <p className="regular-font text-[#595959] text-sm mt-4 text-center mx-auto ">
                        WhatsApp
                      </p>
                    </div>
                    {/* end of a session */}
                  </div>
                </div>
                <div className="flex flex-col mt-12 mb-6">
                  <h1 className="text-black lg:text-2xl font-extrabold sm:text-xl text-lg  title-font-su mb-6">
                    Copy your link
                  </h1>
                  <div className="w-full  bg-[#f6f6f6] px-5 py-4 flex items-center justify-between rounded-[12px]">
                    <p className="font-regular text-[#777] text-xs sm:text-sm lg:text-xl">
                      https://buymejollof.com
                    </p>
                    <button
                      onClick={() => copyToClipboard(urlWebsite)}
                      className="cursor-pointer"
                    >
                      <Copy />
                    </button>
                  </div>
                  {copied && (
                    <p className="text-center text-sm text-[#595959] mt-4">
                      Copied to clipboard!
                    </p>
                  )}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
