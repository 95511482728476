import Footer from "./components/footer";
import Navbar from "./components/navbar";

const Privacy = () => {
  return (
    <section>
      <section className="bg-style bg-white flex flex-col items-center justify-center pt-8 px-4 pb-16  lg:pt-10 lg:px-[70px]">
        <Navbar />

        <section className=" py-20">
          <h1 className="text-3xl lg:text-5xl text-black text-center title-font-bd">
            Privacy Policy
          </h1>
        </section>
      </section>
      <section className="py-4">
        <div className="bg-white pt-8 lg:px-28 lg:mb-32 2xl:px-96">
          <section className="max-w-[760px] mx-auto">
            <h1 className="text-xl font-bold">1. Introduction</h1>
            <p className="text-base mt-3 regular-font">
              Buymejollof is a platform for creators to accept support from
              their audience and share exclusive content. At Buymejollof, your
              privacy is important to us, and we want you to feel confident that
              your personal information is secure when using our products and
              our platform.
            </p>
            <p className="text-base mt-3 regular-font">
              The services are provided by Buymejollof Ltd (hereinafter referred
              to as “Buymejollof”), a Nigeria company with registered address at
              6 Ebun Adegbamigbe avenue, seaside estate, Badore Ajah, Lagos
              Nigeria. It is Buymejollof’s policy to respect your privacy
              regarding any information we may collect while operating our
              website.
            </p>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">2. Website visitors</h1>
              <p className="text-base regular-font mt-3">
                Like most website operators, Buymejollof collects
                non-personally-identifying information of the sort that web
                browsers and servers typically make available, such as the
                browser type, language preference, referring site, and the date
                and time of each visitor request. Buymejollof's purpose in
                collecting non-personally identifying information is to better
                understand how Buymejollof's visitors use its website.
              </p>
              <p className="text-base regular-font mt-3">
                From time to time, Buymejollof may release
                non-personally-identifying information in the aggregate, e.g.,
                by publishing a report on trends in the usage of its website.
                Buymejollof also collects potentially personally-identifying
                information like Internet Protocol (IP) addresses for logged in
                users and for users making payments on Buymejollof.com.
                Buymejollof only discloses logged in user and commenter IP
                addresses under the same circumstances that it uses and
                discloses personally-identifying information as described below,
                except that payee IP addresses and email addresses are visible
                and disclosed to the administrators of Buymejollof and is
                handled by payment processors at the time of processing the
                payments.
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">3. Payments</h1>
              <p className="text-base regular-font mt-3">
                To make a payment to a creator on Buymejollof, you have to
                provide our payment processor (Flutterwave & Paystack) with your
                payment information. They provide us with a token that
                represents your account, your card’s expiration date, card type
                and the last four digits of your credit card. If you provide
                them with a name and email address then they also provide us
                with that information.
              </p>
              <p className="text-base regular-font mt-3">
                We collect and process information about the creators you
                support, the level at which you support them, what rewards you
                receive and how often you support them.
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">
                4. Gathering of Personally-Identifying Information
              </h1>
              <p className="text-base regular-font mt-3">
                Certain visitors to Buymejollof's websites choose to interact
                with Buymejollof in ways that require Buymejollof to gather
                personally-identifying information. The amount and type of
                information that Buymejollof gathers depends on the nature of
                the interaction. For example, we ask visitors who sign up at
                Buymejollof.com to provide a username and email address.
              </p>
              <p className="text-base regular-font mt-3">
                Those who engage in transactions with Buymejollof are asked to
                provide additional information, including as necessary the
                personal and financial information required to process those
                transactions. In each case, Buymejollof collects such
                information only insofar as is necessary or appropriate to
                fulfill the purpose of the visitor's interaction with
                Buymejollof.
              </p>
              <p className="text-base regular-font mt-3">
                Buymejollof does not disclose personally-identifying information
                other than as described below. And visitors can always refuse to
                supply personally-identifying information, with the caveat that
                it may prevent them from engaging in certain website-related
                activities.
              </p>
              <p className="text-base regular-font mt-3">
                You can request to delete your personal data by emailing us at
                support@Buymejollof.com.
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">
                4. Aggregated Statistics
              </h1>
              <p className="text-base regular-font mt-3">
                Buymejollof may collect statistics about the behavior of
                visitors to its websites. Buymejollof may display this
                information publicly or provide it to others. However,
                Buymejollof does not disclose personally-identifying information
                other than as described below.
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">
                5. Protection of Certain Personally-Identifying Information
              </h1>
              <p className="text-base regular-font mt-3">
                Buymejollof discloses potentially personally-identifying and
                personally-identifying information only to those of its
                employees, contractors and affiliated organizations that (i)
                need to know that information in order to process it on
                Buymejollof's behalf or to provide services available at
                Buymejollof's websites, and (ii) that have agreed not to
                disclose it to others. Some of those employees, contractors and
                affiliated organizations may be located outside of your home
                country; by using Buymejollof's websites, you consent to the
                transfer of such information to them
              </p>
              <p className="text-base regular-font mt-3">
                Buymejollof will not rent or sell potentially
                personally-identifying and personally-identifying information to
                anyone. Other than to its employees, contractors and affiliated
                organizations, as described above, Buymejollof discloses
                potentially personally-identifying and personally-identifying
                information only in response to a subpoena, court order or other
                governmental request, or when Buymejollof believes in good faith
                that disclosure is reasonably necessary to protect the property
                or rights of Buymejollof, third parties or the public at large.
                If you are a registered user of an Buymejollof website and have
                supplied your email address, Buymejollof may occasionally send
                you an email to tell you about new features, solicit your
                feedback, or just keep you up to date with what's going on with
                Buymejollof and our products
              </p>
              <p className="text-base regular-font mt-3">
                If you send us a request (for example via email or via one of
                our feedback mechanisms), we reserve the right to publish it in
                order to help us clarify or respond to your request or to help
                us support other users. Buymejollof takes all measures
                reasonably necessary to protect against the unauthorized access,
                use, alteration or destruction of potentially
                personally-identifying and personally-identifying information.
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">6. Account Deletion</h1>
              <p className="text-base regular-font mt-3">
                You may stop using our Service, by contacting Buymejollof at
                support@Buymejollof.com, and requesting account deletion. We may
                retain certain information as required by law or as necessary
                for our legitimate business purposes. All provisions of this
                agreement survive termination of an account, including our
                rights regarding any content you’ve already submitted to the
                Site. (For instance, if you’ve launched a page, deleting your
                account will not automatically remove the page from the Site.)
                If you have signed up for a membership, you can find information
                about your cancellation rights on our site.
              </p>

              <p className="text-base regular-font mt-3">
                You will need to cancel your subscription in accordance with
                these cancellation rights to stop your recurring payments for
                the relevant subscription. Merely deleting your account without
                canceling your subscription will not stop these payments. You
                can request to delete your personal data by emailing us at
                support@Buymejollof.com.
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">7. Cookies</h1>
              <p className="text-base regular-font mt-3">
                A cookie is a string of information that a website stores on a
                visitor's computer, and that the visitor's browser provides to
                the website each time the visitor returns. For more information,
                visit{" "}
                <a href="https://www.allaboutcookies.org/">
                  All About Cookies.
                </a>
              </p>

              <p className="text-base regular-font mt-3">
                Buymejollof uses cookies to help Buymejollof identify and track
                visitors, their usage of Buymejollof website, and their website
                access preferences. Some cookies expire after a certain amount
                of time, or upon logging out (session cookies), others remain on
                your computer or terminal device for a longer period (persistent
                cookies). Our Site uses first party cookies (cookies set
                directly by Anchor) as well as third party cookies, as described
                below:
              </p>
              <p className="text-base regular-font mt-3">
                Strictly Necessary Cookies: Used to provide Users with the
                Services available through our Site and to use some of their
                features, such as the ability to log-in and access to secure
                areas. These cookies are served by Anchor and are essential for
                using and navigating the Site. Without these cookies, basic
                functions of our Site would not work. Because these cookies are
                strictly necessary to deliver the Site and the Services, you
                cannot refuse them.
              </p>
              <p className="text-base regular-font mt-3">
                - Analytics/Performance: Used to better understand the behavior
                of the Users on our Site and improve our Site accordingly, for
                example by making sure Users are finding what they need easily.
                The Site uses Google Analytics, a web analytics service provided
                by Google Inc. (“Google”). The information collected by Google
                (including your IP address) will be transmitted to and stored by
                Google on servers in the United States (Google is certified to
                the Privacy Shield for data transfers). How long a Google
                Analytics cookie remains on your computer or device depends on
                what it is and what it is used for. Some Google Analytics
                cookies expire at the end of your browser session, whilst others
                can remain for up to two years. You can prevent your data from
                being collected by Google Analytics on our Site by downloading
                and installing the Google Analytics Opt-out Browser Add-on for
                your current web browser. For more information on Google
                Analytics privacy practices, read here.
              </p>
              <p className="text-base regular-font mt-3">
                Buymejollof visitors who do not wish to have cookies placed on
                their computers should set their browsers to refuse cookies
                before using Buymejollof's websites, with the drawback that
                certain features of Buymejollof's websites may not function
                properly without the aid of cookies.
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">8. Business Transfers</h1>
              <p className="text-base regular-font mt-3">
                If Buymejollof, or substantially all of its assets, were
                acquired, or in the unlikely event that Buymejollof goes out of
                business or enters bankruptcy, user information would be one of
                the assets that is transferred or acquired by a third party. You
                acknowledge that such transfers may occur, and that any acquirer
                of Buymejollof may continue to use your personal information as
                set forth in this policy.
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">9. Ads</h1>
              <p className="text-base regular-font mt-3">
                We do not run any external advertisements on Buymejollof.
                However, we use ad networks such as Twitter Ads to collect
                retargeting information to compile information about our users.
                This Privacy Policy covers the use of cookies by Buymejollof and
                does not cover the use of cookies by any advertisers.
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">
                10. Prohibited and restricted content
              </h1>
              <p className="text-base regular-font mt-3">
                We want Buymejollof to be a safe venue for a diverse range of
                creators and their communities. Please respect the people and
                communities that are building with you on Buymejollof. If you
                find any creators breaking our permitted content guidelines, you
                can report them to us. Alternatively, you can block them
                permanently, and we’ll no longer show you their profile or
                content posted by them.
              </p>

              <p className="text-base regular-font mt-3">
                These guidelines have been created by the community after a lot
                of careful thought, with lots of help from experts and creators
                like you. We think they’ll help you build a safe environment to
                help you continue to create and share your work with them.
              </p>

              <p className="text-base regular-font mt-3">
                - Harassment : We prohibit users from posting content that
                harasses other people or businesses or encourages other people
                to participate in harassment. This includes content that
                contains direct or indirect threats of physical harm against
                individuals or groups.
              </p>

              <p className="text-base regular-font mt-3">
                - Hate speech : We believe that all people—regardless of race,
                ethnicity, religion, disability, age, nationality, veteran
                status, sexual orientation, gender identity and expression,
                caste, or any other protected status—have a right to access
                information and participate in its exchange without being
                subjected to hate speech. Do not post or share content that
                promotes violence, hate speech, or dehumanization toward any
                individual or group.
              </p>

              <p className="text-base regular-font mt-3">
                - Personal information : We don’t allow content that contains
                private or confidential information such as credit card details,
                medical records, or government-issued identification. This
                includes yours or someone else’s.
              </p>

              <p className="text-base regular-font mt-3">
                - Impersonation : Don’t use Buymejollof to impersonate any
                person, group, or organization. Please do not impersonate others
                or post content pretending to be a verified authoritative
                source.
              </p>

              <p className="text-base regular-font mt-3">
                - Sexually explicit content : We don't allow sexually explicit
                content on Buymejollof. We want everyone on our platform to feel
                safe and comfortable so they can share their work.
              </p>

              <p className="text-base regular-font mt-3">
                This includes
                <ul style={{ padding: 16, margin: 14, listStyle: "ordered" }}>
                  <li className="regular-font text-base">
                    Pornography or the depiction of sexual acts, genitals, or
                    fetishes intended to be sexually gratifying.
                  </li>
                  <li className="regular-font text-base">
                    Content that includes nude genitalia.
                  </li>
                  <li className="regular-font text-base">
                    Content that glorifies or promotes bestiality.
                  </li>
                  <li className="regular-font text-base">
                    Content that glorifies or promotes sexually explicit content
                    or behavior.
                  </li>
                  <li className="regular-font text-base">
                    Regulated, dangerous, & Illegal :
                  </li>
                </ul>
                <p className="regular-font text-base">This includes :</p>
              </p>
              <p className="text-base regular-font mt-3">
                Dangerous content that promotes dangerous activities that could
                result in serious physical harm to the person committing the
                act, those around them, or animals.
              </p>
              <p className="text-base regular-font mt-3">
                Illegal content on dangerous or illegal acts such as rape, organ
                sale, human trafficking, or images or any other content that
                infringes on anyone else’s legal rights, including copyright.
                For more information or to file a DMCA request, review our
                copyright procedures or content produced by or on behalf of
                terrorist groups.
              </p>
              <p className="text-base regular-font mt-3">
                Child safety content that depicts the nudity of apparent minors.
              </p>
              <p className="text-base regular-font mt-3">
                Terrorist content that incites violence promotes terrorist acts
                or celebrates terrorist attacks.
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">11. International Users</h1>
              <p className="text-base regular-font mt-3">
                Buymejollof is based in Nigeria. If you are accessing our
                Services from the European Union or other regions with laws
                governing data collection and use, please note that your
                Personal Data will be transmitted to our servers in the United
                States and the data may be transmitted to our service providers
                supporting our business operations (described above).
              </p>
              <p className="text-base regular-font mt-3">
                The United States may have data protection laws less stringent
                than or otherwise different from the laws in effect in the
                country in which you are located. Where we transfer your
                Personal Data out of the EU we will take steps to ensure that
                your Personal Data receives an adequate level of protection
                where it is processed and your rights continue to be protected
                (GDPR).
              </p>
              <p className="text-base regular-font mt-3">
                By providing your information to the Services you agree to the
                transfer of your information to the United States and processing
                globally in accordance with this Privacy Policy.
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">
                12. Privacy Policy Changes
              </h1>
              <p className="text-base regular-font mt-3">
                Although most changes are likely to be minor, Buymejollof may
                change its Privacy Policy from time to time, and in
                Buymejollof's sole discretion. Buymejollof encourages visitors
                to frequently check this page for any changes to its Privacy
                Policy. If you have a Buymejollof account, you might also
                receive an alert informing you of these changes. Your continued
                use of this site after any change in this Privacy Policy will
                constitute your acceptance of such change.
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">
                13. Other Terms and Conditions
              </h1>
              <p className="text-base regular-font mt-3">
                Your access to and use of the Buymejollof is subject to any
                additional terms applicable to such Services that may be posted
                on the Terms from time to time, including without limitation,
                Buymejollof's Terms of Use available at
                https://buymejollof.com/terms.
              </p>
              <p className="text-base regular-font mt-3">
                By agreeing to our privacy policy you are also agreeing to the
                Google's privacy policy for the services which we use like
                Google Sign In, Recaptcha, Analytics, etc. which can be found
                here: https://policies.google.com/privacy?hl=en-US
              </p>
            </div>

            <div className="w-full mt-8">
              <h1 className="text-xl font-bold title-font-bd">Contact Us</h1>
              <p className="text-base font-normal regular-font mt-3">
                Please feel free to contact us if you have any questions about
                Buymejollof’s Privacy Policy or the information practices. You
                may contact us as follows: You may send an email to
                support@buymejollof.com, or send mail to: Buymejollof
                <a href="mailto:support@buymejollof.com" className="underline">
                  support@buymejollof.com
                </a>
              </p>
              <p className="mt-3">Write to us at:</p>
              <p className="regular-font">
                6 Ebun Adegbamigbe avenue, Seaside Estate Nigeria
              </p>{" "}
              <p className="regular-font">Badore, Ajah, Lagos. Nigeria</p>{" "}
              <p className="mt-3">Call us at:</p>
              <p>+2347066560656</p>
            </div>
          </section>
        </div>
      </section>
      <section className="pt-10">
        <Footer />
      </section>
    </section>
  );
};

export default Privacy;
