import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Privacy from "./Privacy";
import Terms from "./Terms";
import AmlPolicy from "./Aml";
import Refund from "./Refund";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";

import PublicPage from "./pages/PublicPage";

import CreatorsPage from "./pages/PublicPage/CreatorsPage";
import Memberships from "./pages/PublicPage/Membership";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/aml-policy" element={<AmlPolicy />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/:id" element={<PublicPage />}>
              <Route index element={<CreatorsPage />} />
              <Route path="membership" element={<Memberships />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
