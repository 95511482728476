import { Arrow, Blob, Connectivity, Line } from "../assets";
import Card from "./Card";

const Delight = () => {
  const isMobile = window.innerWidth <= 425;
  return (
    <section className="px-4 lg:px-28 2xl:px-96">
      <div className="relative md:top-14 lg:top-20 w-full flex flex-col items-center justify-center">
        <h1 className="text-3xl md:text-5xl lg:text-[64px] xl:text-[68px] title-font-su text-center lg:leading-[80px] flex z-10 relative">
        Delight your supporters <br /> like never before
        </h1>
        <Line className="absolute lg:right-64 hidden" />
        <Blob className="absolute left-0 right-0 top-[-10px] m-auto z-[-10px] opacity-30" />
      </div>
      <section className="flex flex-col lg:flex-row gap-6 pt-12 md:pt-40 lg:pt-[200px]">
        <Card
          title="Share real-time BTS with fans"
          body="Enough of keeping up with Gram. Save the meaty gist for fans who truly care about you 😉"
          imageUrl="v1693807755/marketing-website/Upload_video_ukvhmi.png"
          imageClassnames="w-[320px] lg:w-[565px] absolute bottom-[-20px] left-0 right-0 m-auto"
          cardBg="bg-purple-light"
          height="h-[360px] lg:h-[573px]"
        />
        <Card
          title="Send and receive DM’s from top supporters"
          body="Bring the D in DMs back. Give your supporters a direct and easier way to message you with just a click!"
          imageUrl="v1693805981/marketing-website/Chats_ys8iyv.png"
          imageClassnames="w-[400px] md:w-[360px] lg:w-[574px] absolute bottom-[-20px]"
          cardBg="bg-green-light"
          height="h-[360px] md:h-[400px] lg:h-[573px]"
        />
      </section>
      <section className="pt-6 relative">
        <Card
          title="Personalized Requests"
          body="Give your supporters the magical moments they deserve on their birthdays, milestones, achievements, anniversaries, or even a well-deserved roast. Let your fans request a video, TikTok, or IG reels, and get paid."
          imageUrl="v1693813269/marketing-website/request-min_fgnnac.png"
          cardBg="bg-orange-light"
          imageClassnames="w-[700px] md:w-[500px] lg:w-[700px] absolute bottom-2"
          textClassname="text-center"
          textWidth="lg:w-[672px]"
          height="h-[360px] md:h-[450px] lg:h-[573px]"
          centered={true}
        />
        <Arrow className="absolute bottom-[-70px] lg:bottom-[-50px] left-6 lg:left-14" width={isMobile && 70} />
        <Arrow className="absolute bottom-[-40px] lg:bottom-[40px] right-2 lg:right-20 rotate-0 scale-x-[-1]" width={isMobile ? 70 : 199}/>
        <Connectivity className="absolute top-14 right-2 lg:right-[260px] md:hidden" width={isMobile && 20} height={isMobile && 20}/>
      </section>
    </section>
  );
};

export default Delight;
