import { AdvancedImage } from "@cloudinary/react"
import AssetTransform from "../utils"
import { Pattern } from "../assets";

const SuperFans = () => {
  return (
    <section className="relative overflow-hidden mt-[-11px]">
      <AdvancedImage 
        cldImg={AssetTransform(
          "v1693640963/marketing-website/happy_superfans-min_txjaax"
        )}
      />
      <div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
      <span className="absolute bottom-0 left-[-20px] mt-[-11px]"><Pattern color='white'/></span>
    </section>
  )
}

export default SuperFans;