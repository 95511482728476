import Loader from ".";

interface IPageLoader {
  color?: string;
  title: string;
  body: string;
}

const PageLoader = ({ color, title, body }: IPageLoader) => {
  return (
    <section className="mt-6 flex flex-col justify-center items-center">
      <Loader color={color} />
      <h6 className="pt-4  font-gt-w-medium">{title}</h6>
      <p className="font-gt-w-regular">{body}</p>
    </section>
  );
};


export default PageLoader;