import axios from "axios";
import { setStorageValue } from "./localStorage";
import { toast } from "sonner";
import { getSimplifiedError } from "../utils/index";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const url = {
  login: "login",
  register: "signup/client",
  resend: "email/verify/resend",
  reset: "reset",
  verify: "email/verify",
  profile: "profile",
  account_setup: "client/profile",
  banks: 'bank/account',
  creatorspage: 'supporter/jollof',
  membership: "jollof/plan",
};


export let ApiService = axios.create({
  baseURL: baseUrl,
});



export const getBanks = () => {
  console.log("i got here");
  axios
    .get("https://api.paystack.co/bank", {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_SECRET}`,
      },
    })
    .then((res) => {
      console.log({ res });
      const data = res.data.data.map((bank: any) => {
        return {
          ...bank,
          label: bank.name,
          value: bank.name,
        };
      });

      console.log({ newData: data });
      setStorageValue({ key: "bmj_banks", value: data });
    })
    .catch((err) => {
      toast.error("An error occurred while fetching banks");
    });
};

export const verifyAccountNumber = async (payload: any) => {
  console.log({ payload });
  try {
    const res = await axios.get(
      `https://api.paystack.co/bank/resolve?account_number=${payload?.account_number}&bank_code=${payload?.bank_code}`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_SECRET}`,
        },
      }
    );
    const data = res.data.data;
    return data;
  } catch (err) {
    getSimplifiedError(err);
  }
};
