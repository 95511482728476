import { AdvancedImage } from "@cloudinary/react";
import { Blob, EurekaIcon, Messages } from "../assets";
import Navbar from "./navbar";
import AssetTransform from "../utils";
import Reserve from "./Reserve";

const Masthead = () => {
  return (
    <header className="bg-white flex flex-col items-center justify-center pt-8 px-4 pb-16 md:pb-40 lg:pb-48 lg:pt-10 lg:px-[70px] 2xl:px-80 relative overflow-hidden z-10 ">
      <Navbar />
      <div
        className="w-full lg:w-[875px] pt-12 md:pt-20 lg:pt-40 flex flex-col z-q0 relative"
        id="reserve"
      >
        <h1 className="text-2xl md:text-5xl lg:text-[68px] title-font-su text-center md:leading-[60px] lg:leading-[80px] relative z-10">
          <span className="absolute left-14 top-[-27px] md:top-[-20px] md:left-40 lg:top-[-30px] lg:left-0">
            <EurekaIcon className="w-6 sm:w-6 md:w-20 lg:w-40" />
          </span>
          Grow your community <br /> Get real support from fans.
        </h1>
        <p className="text-sm md:text-2xl lg:text-2xl text-center pt-2 lg:pt-4 regular-font relative">
          By easily connecting with your audience and transforming them into
          your biggest supporters! Launch memberships, share exclusive content,
          and offer rewards to your supporters. Let's turn your fans' support
          into your superpower
          <Messages className="absolute top-0 right-[-100px]" />
        </p>
        <Reserve />
        <AdvancedImage
          cldImg={AssetTransform(
            "v1693808133/marketing-website/Creator-4-min_iwn3sh.png"
          )}
          className="w-10 lg:w-16 2xl:w-20 2xl:h-20 absolute right-[-30px]"
        />
        <AdvancedImage
          cldImg={AssetTransform(
            "v1693808132/marketing-website/Creator-2-min_om1fds.png"
          )}
          className="w-16 h-16 absolute left-[-80px]"
        />
        <AdvancedImage
          cldImg={AssetTransform(
            "v1693808133/marketing-website/Creator-3-min_ogu72u.png"
          )}
          className="w-[100px] h-[100px] absolute bottom-[160px] left-[-220px]"
        />
        <AdvancedImage
          cldImg={AssetTransform(
            "v1693808132/marketing-website/creator-1-min_dnmfvv.png"
          )}
          className="w-[40px] left-[-40px] lg:w-[100px] lg:h-[100px] absolute bottom-0 lg:left-[-80px]"
        />
      </div>

      <AdvancedImage
        cldImg={AssetTransform(
          "v1693644233/marketing-website/lifestyle_creator_1_-min_aztrfo"
        )}
        className="w-[60px] lg:w-[150px] xl:w-[297px] 2xl:w-[297px] 2xl:h-[230px] absolute bottom-0 right-0"
      />

      <Blob className="absolute left-0 right-0 m-auto z-[-10px] opacity-30" />
    </header>
  );
};

export default Masthead;
