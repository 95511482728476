import { AdvancedImage } from "@cloudinary/react";
import { Blob } from "../assets";
import Card from "./Card";
import AssetTransform from "../utils";

const Support = () => {
  return (
    <section className="px-4 bg-white pt-20 lg:pt-28 lg:px-28 2xl:px-96">
      <div className="relative lg:top-20">
        <h1 className="text-3xl md:text-5xl lg:text-[64px] xl:text-[68px] title-font-su text-center lg:leading-[80px] flex z-10 relative">
          Make supporting you easy and safe for your fans.
        </h1>
        <Blob className="absolute left-0 right-0 top-[-10px] m-auto z-[-10px] opacity-30" />
      </div>
      <div className="mt-20 lg:mt-[204px] flex flex-col  md:flex-row lg:flex-row justify-between gap-10">
        <div className="w-full md:w-96 lg:w-96">
          <AdvancedImage
            cldImg={AssetTransform(
              "v1693876092/marketing-website/Frame_366-min_v505am.png"
            )}
            alt="support"
            className="w-[553px]"
          />
        </div>
        <div className="w-full md:w-3/4 lg:w-3/6">
          <h1 className="text-2xl lg:text-5xl text-mainAsh title-font-bd lg:leading-[60px]">
            Receive one-time or monthly support from fans
          </h1>
          <p className="regular-font text-sm lg:text-xl pt-4 pb-10 lg:py-10">
            Your super fans can now support your craft in just a couple of taps,
            they don't even need to create an account!
          </p>
          {/* <Reserve type="dark" /> */}
        </div>
      </div>
      <section className="flex flex-col lg:flex-row gap-6 pt-10 lg:pt-[140px]">
        <Card
          title="Moments"
          body="From online conversation to concerts, Moments is the simplest way to interact with your fans and supporters. Create special, virtual, or physical ticketed experiences for your fans and supporters—no matter where they are in the world."
          imageUrl="v1693874757/marketing-website/moments-min_gewdnc.png"
          cardBg="bg-blue-light"
          imageClassnames="w-[300px] lg:w-[500px] absolute bottom-[-20px]"
          height="h-[550px] lg:h-[816px]"
          isComing={true}
          tagClassname="bg-blue-light-darker"
        />
        <Card
          title="Shops"
          body="Whatever you want to sell, from digital to physical products, it’s easier with Buymejollof 😉
          "
          imageUrl="v1693875423/marketing-website/shop-min_uzg8bh.png"
          cardBg="bg-red-light"
          height="h-[500px] md:h-[600px] lg:h-[816px]"
          isComing={true}
          imageClassnames="w-[526px] md:w-[500px] lg:w-[700px] absolute bottom-[-20px]"
          tagClassname="bg-red-light-darker text-white"
        />
      </section>
    </section>
  );
};

export default Support;
