import {
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactNode,
  ReactPortal,

} from "react";
import CurrencyFormat from "react-currency-format";

import { CheckCircle } from "../../../assets/icons";
import AppButton from "../../../components/AppButton";

import { getCurrency } from "../../../utils/index";
import { IMembershipData } from "../../../types";



const MembershipCard = ({
  data,
  onClick,
}: {
  data: IMembershipData;
  onClick: (data: IMembershipData) => void;
}) => {


  return (
    <section className="w-full flex flex-col justify-between bg-white border-[1px] border-neutral-400 p-4 md:p-6 rounded-3xl max-h-fit md:h-[500px]">
      <section>
        <div className="flex items-center justify-between">
          <span className="font-gt-w-medium text-xs md:text-sm bg-neutral-50 px-2 py-2 md:px-4 md:py-3 rounded-full">
            {data?.name}
          </span>
        </div>
        <h1 className="relative mt-0">
          <CurrencyFormat
            value={data?.amount} //come back to convert to kobo/cent
            prefix={getCurrency(data?.currency)}
            thousandSeparator={true}
            displayType="text"
            className="text-2xl md:text-[40px] font-gt-w-medium text-green-g300"
          />
          <span className="absolute text-xs md:text-sm font-gt-w-regular top-3 md:top-5 text-green-g300 pl-2">
            per {data?.interval}
          </span>
        </h1>
        <p className="text-sm md:text-base font-gt-w-regular py-4 md:py-5">{data?.description}</p>
        <div className="">
          <h6 className="text-sm font-gt-w-medium text-neutral-700 uppercase">
            Rewards
          </h6>
          <ul className="mt-2">
            {data?.rewards?.length ? (
              data?.rewards.map(
                (
                  reward: {
                    title:
                      | string
                      | number
                      | boolean
                      | ReactElement<any, string | JSXElementConstructor<any>>
                      | Iterable<ReactNode>
                      | ReactPortal
                      | null
                      | undefined;
                  },
                  i: Key | null | undefined
                ) => {
                  return (
                    <li
                      key={i}
                      className="flex items-center gap-2 text-sm font-gt-w-regular text-neutral-700 mb-[14px]"
                    >
                      <span>
                        <CheckCircle />
                      </span>
                      {reward.title}
                    </li>
                  );
                }
              )
            ) : (
              <li className="flex items-center gap-2 text-xs md:text-sm font-gt-w-regular text-neutral-700 mb-[14px]">
                <span>
                  <CheckCircle />
                </span>
                You just want to support me on a monthly basis. Awwnnn, thank
                you
              </li>
            )}
          </ul>
        </div>
      </section>
      <section className="mt-5 md:mt-0">
        <AppButton
          intent="primary"
          size={"medium"}
          fullWidth={true}
          title="Join Membership"
          onClick={() => onClick(data)}
          isLoading={false}
        />
      </section>
    </section>
  );
};

export default MembershipCard;
