import Footer from "./components/footer";
import Navbar from "./components/navbar";

const AmlPolicy = () => {
  return (
    <section>
      <section className="bg-style bg-white flex flex-col items-center justify-center pt-8 px-4 pb-16  lg:pt-10 lg:px-[70px]">
        <Navbar />

        <section className=" py-20">
          <h1 className="text-3xl lg:text-5xl text-black text-center title-font-bd">
            AML Policy
          </h1>
        </section>
      </section>
      <section className="py-4">
        <div className="bg-white pt-8 lg:px-28 lg:mb-32 2xl:px-96">
          <section className="max-w-[760px] mx-auto">
            <h2 className="text-xl font-bold title-font-bd">
              <strong>Definition</strong>
            </h2>

            <ol style={{ padding: 16, margin: 14, listStyle: "ordered" }}>
              <li className="regular-font text-base">
                <strong>Money Laundering (ML):</strong> means the process by
                which criminals attempt to conceal the origin and/or
                illegitimate ownership of property and assets that are the
                proceeds of criminal activities.
              </li>
              <li className="regular-font text-base">
                <strong>Terrorism Financing:</strong> includes both legitimate
                and illegitimate money characterized by concealment of the
                origin or intended criminal use of the funds.
              </li>
              <li className="regular-font text-base">
                <strong>Know Your Customer (KYC):</strong> This entails
                obtaining and verifying customer identity, preservation of
                records of customers, mandatory disclosure of transactions to
                authorized statutory bodies.
              </li>
              <li className="regular-font text-base">
                <strong>Nigeria Financial Intelligence Unit (NFIU):</strong> The
                Nigerian arm of the global Financial Intelligence Unit (FIU).
              </li>
              <li className="regular-font text-base">
                <strong>Politically Exposed Persons (PEPs):</strong> Individuals
                who have been entrusted with prominent public functions in any
                country. Generally presenting a higher risk for potential
                involvement in bribery and corruption by virtue of their
                position and the influence that they may hold.
              </li>
              <li className="regular-font text-base">
                <strong>Enterprise Risk Management (ERM):</strong> includes the
                methods and processes used by organizations to manage risks and
                seize opportunities related to achieving their objectives.
              </li>
              <li className="regular-font text-base">
                <strong>Client:</strong> includes any entity with a business
                relationship with the Company or any entity connected with a
                financial transaction that can pose significant reputational or
                other risks to the Company.
              </li>
            </ol>

            <h3 className="text-xl font-bold title-font-bd">
              <strong>Policy Mandate</strong>
            </h3>

            <p className="text-base regular-font mt-3">
              This policy is connected to a more comprehensive in-house
              Anti-Money Laundering (AML), Know Your Customer (KYC) and the
              combating the Financing of Terrorism Policy (CFT). It sets out an
              abbreviated version of the guidelines for Buymejollof Limited
              (herein referred to as ‘the Company’) compliance with AML/CFT
              obligations under the law, as well as regulatory directives. It
              prevents any transaction that facilitates criminal activities.
            </p>

            <p className="text-base regular-font mt-3">
              Buymejollof Ltd understands that it has a vital role in preventing
              criminals from using our services to facilitate money laundering
              and terrorist financing. We are committed to detecting and
              avoiding money laundering and will cooperate fully with the
              Regulators and Law Enforcement Agencies where necessary.
            </p>

            <h3 className="text-xl font-bold title-font-bd mt-10">
              <strong>Policy Description</strong>
            </h3>

            <p className="text-base regular-font mt-3">
              Money Laundering and financing terrorism are financial crimes with
              economic effects. This policy is to eradicate the adverse effects
              of criminal economic activity and promotes integrity and stability
              in financial markets. Compliance with this policy is also critical
              to preserving the Company’s corporate integrity, reputation and
              operational efficiency.
            </p>
            <p className="text-base regular-font mt-3">
              The purpose of this Policy is:
            </p>
            <ul style={{ padding: 16, margin: 14, listStyle: "ordered" }}>
              <li className="regular-font text-base">
                To guide the standard of conduct and practice that Buymejollof
                must follow in implementing the AML, KYC, and CFT regulations.
              </li>
              <li className="regular-font text-base">
                To protect the Company against fraud, reputational and other
                financial market risks.
              </li>
              <li className="regular-font text-base">
                To minimize the risks faced by the Company from proceeds of
                crime.
              </li>
              <li className="regular-font text-base">
                To prevent money laundering and establish ERM systems to monitor
                the Company’s exposure to financial crime.
              </li>
            </ul>

            <h3 className="text-xl font-bold title-font-bd mt-10">
              <strong>Compliance with Policy</strong>
            </h3>

            <p className="text-base regular-font mt-3">
              The Company shall comply with the following:
            </p>
            <ul style={{ padding: 16, margin: 14, listStyle: "ordered" }}>
              <li className="regular-font text-base">
                Formulate and implement internal controls and other procedures
                that will deter criminals from using its facilities for money
                laundering and terrorist financing and ensure that its
                obligations under Nigerian and subsisting laws and Regulations
                are met.
              </li>
              <li className="regular-font text-base">
                Embark on Enterprise Risk Management (ERM) and maintain an ERM
                register.
              </li>
              <li className="regular-font text-base">
                Designate a Money Laundering Reporting Officer (MLRO) with the
                relevant competence and independence to implement the Company’s
                AML/KYC compliance policy.
              </li>
              <li className="regular-font text-base">
                Comply with the Money Laundering (Prohibition) Act, the Money
                Laundering (Prohibition) (Amendment) Act (together, the Money
                Laundering Act) and the Economic and Financial Crimes Commission
                Act.
              </li>
              <li className="regular-font text-base">
                Identify and report any suspicious transactions from the
                criminal activities defined in AML/KYC Regulations.
              </li>
              <li className="regular-font text-base">
                Ensure the implementation of the AML/KYC Act requirements is not
                inhibited through the Company’s Confidentiality Agreement or
                Policy.
              </li>
              <li className="regular-font text-base">
                Effectively communicate and raise staff awareness on AML/KYC
                issues.
              </li>
              <li className="regular-font text-base">
                Establish and maintain a risk-based approach to assessing and
                managing money laundering and terrorist financing risks.
              </li>
            </ul>

            <h3 className="text-xl font-bold title-font-bd mt-10">
              <strong>Know Your Client</strong>
            </h3>

            <p className="text-base regular-font mt-3">
              KYC is the due diligence that the Company must perform to identify
              their clients and ascertain relevant information before carrying
              out any financial businesses with them. The Company, in upholding
              its KYC policies, shall undertake the following:
            </p>
            <ul style={{ padding: 16, margin: 14, listStyle: "ordered" }}>
              <li className="regular-font text-base">
                Establish and maintain a risk-based approach to Customer Due
                Diligence (CDD), Enhanced Due Diligence (EDD), including
                customer identification, verification, and KYC procedures.
              </li>
              <li className="regular-font text-base">
                Issue a KYC compliance form to clients.
              </li>
              <li className="regular-font text-base">
                Obtain the necessary documents and information from every
                client.
              </li>
              <li className="regular-font text-base">
                Report suspicious activities and transactions to the regulatory
                authorities.
              </li>
              <li className="regular-font text-base">
                Update client information as and when available.
              </li>
              <li className="regular-font text-base">
                Identify the client and verify the client’s identity using
                reliable, independent source documents (e.g. passport data page,
                national ID card, voters card, utility bills stating their
                current postal address, etc.), data or information.
              </li>
              <li className="regular-font text-base">
                Verify the legal status of business names, incorporated
                trustees, and companies with the Corporate Affairs Commission.
              </li>
              <li className="regular-font text-base">
                Refuse to transact business with “shell companies” as described
                under the International Conventions.
              </li>
              <li className="regular-font text-base">
                Conduct due diligence for higher-risk clients, business
                relationships or transactions including PEP, cross-border
                transactions, and business relationships.
              </li>
            </ul>

            <h3 className="text-xl font-bold title-font-bd mt-10">
              <strong>Record Keeping and Reporting</strong>
            </h3>

            <p className="text-base regular-font mt-3">
              The Company shall keep the record of a customer's identification
              for at least six years after the closure of the account or the
              severance of relations with the customer.
            </p>
            <p className="text-base regular-font mt-3">
              Upon request by a regulatory or law enforcement agency, the
              Company shall make available records related to AML/CFT compliance
              or its clients as soon as possible from the date of the request.
            </p>

            <p className="text-base regular-font mt-3">
              If the company notices any illegal transaction, it shall:
            </p>
            <ul style={{ padding: 16, margin: 14, listStyle: "ordered" }}>
              <li className="regular-font text-base">
                Draw up a report on the identity of the principal and the
                beneficiary or beneficiaries;
              </li>
              <li className="regular-font text-base">
                Take appropriate action to prevent the laundering of the
                proceeds of criminal conduct;
              </li>
              <li className="regular-font text-base">
                Send a copy of the report and action taken to the Nigerian
                Financial Intelligence Unit (NFIU);
              </li>
            </ul>

            <h3 className="text-xl font-bold title-font-bd">
              <strong>Politically Exposed Persons (PEPs)</strong>
            </h3>

            <p className="text-base regular-font mt-3">
              Business relationships with family members or close associates of
              PEPs involve reputation risks similar to those PEPs themselves.
              The Company shall evaluate the risks to its business operations
              when dealing with PEPs.
            </p>

            <h3 className="text-xl font-bold title-font-bd mt-10">
              <strong>Sanctions</strong>
            </h3>

            <p className="text-base regular-font mt-3">
              A breach of the AML/CFT is a severe offence and could lead to
              investigations, imposition of fines and criminal sanctions
              (including imprisonment).
            </p>
          </section>
        </div>
      </section>
      <section className="pt-10">
        <Footer />
      </section>
    </section>
  );
};

export default AmlPolicy;
