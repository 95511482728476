import clsx from "clsx";
import { IUserinfo } from "../../types";
import Thumbnail from "../Thumbnail";

const UserInfo = ({
  withImage,
  title,
  subTitle,
  size,
  url,
  hasWidth,
  userName
}: IUserinfo) => {
  return (
    <section className="flex flex-col md:flex-row gap-3 md:items-center">
      {withImage && <Thumbnail size={size} url={url} />}
      <section className="flex flex-col gap-1 flex-grow">
        <h6 className="font-gt-w-medium m-0 p-0 text-sm md:text-base">{title}{" "} <span className="text-xs text-neutral-600">({userName})</span></h6>
        <p
          className={clsx("font-gt-w-regular text-xs md:text-sm  text-[#999999] m-0 p-0", {
            "w-full md:w-[640px]": hasWidth,
          })}
        >
          {subTitle}
        </p>
      </section>
    </section>
  );
};

export default UserInfo;
