import { ChangeEvent, useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useForm } from "react-hook-form";

import AppInput from "../../../../components/AppInput";
import TextArea from "../../../../components/TextArea";

import { yupResolver } from "@hookform/resolvers/yup";

import { JollofPlate, MinusIcon, PlusIcon } from "../../../../assets/icons";
import { getCurrency } from "../../../../utils/index";
import AppButton from "../../../../components/AppButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";
import { ApiService } from "../../../../utils/endpoints";
import { oneTimeSupportSchema } from "../../../../utils/validation.schema";
import Modal from "../../../../components/Modal/index";
import Loader from "../../../../components/Loader";

import Fireworks from "react-canvas-confetti/dist/presets/fireworks";
import {
  Copy,
  Facebook,
  Telegram,
  Twitter,
  Whatsapp,
} from "../../../../assets";

const SupportWidget = ({ data }: { data: any }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [number, setNumber] = useState("1");
  const [messagePrivate, setPrivate] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    message: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [verifying, setVerifying] = useState(false); //change this when julius makes the callback local
  const [isVerified, setIsVerified] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [verifiedData, setVerifiedData] = useState(false);
  const [referenceId, setReference] = useState("");
  const [showFireworks, setShowFireworks] = useState(false);

  const [copied, setCopied] = useState(false);
  const urlWebsite = window.location.href;

  function removeQueryParams(url: string) {
    const urlObject = new URL(url);
    return `${urlObject.origin}${urlObject.pathname}`;
  }

  const cleanUrl = removeQueryParams(urlWebsite);

  const copyToClipboard = (url: string) => {
    navigator.clipboard.writeText(url);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const size = useWindowSize().width ?? 0;

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(oneTimeSupportSchema),
  });

  const onHandleNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (Number(newValue) >= 1) {
      setNumber(newValue.toString());
    } else {
      setNumber("1");
    }
  };

  const handleChangeValue = (type: string) => {
    if (type === "decrement") {
      if (number === "1") return;
      setNumber((Number(number) - 1).toString());
    }
    if (type === "increment") {
      setNumber((Number(number) + 1).toString());
    }
  };

  const handleCheckBox = (event: ChangeEvent<HTMLInputElement>) => {
    setPrivate(event.target.checked);
  };

  const onHandleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  console.log({ data });

  // Function to parse query parameters
  const getQueryParams = (search: string) => {
    return new URLSearchParams(search);
  };

  const queryParams = getQueryParams(location.search);

  // Accessing individual query parameters
  const trxref = queryParams.get("trxref");
  const reference = queryParams.get("reference");

  console.log({ trxref, reference });

  useEffect(() => {
    if (reference) {
      setReference(reference);
      setVerifying(true);
      setModalVisible(true);
      onVerifyPayment();
    }
  }, [reference]);

  const url = "https://api.fitness.realjay.dev";

  const onHandleSend = (event: any) => {
    const payload = {
      name: userData?.name,
      email: userData?.email,
      message: userData?.message,
      plateCount: number,
      currency: data?.jollofPage?.platePricing[0]?.currency,
      callbackUrl: window.location.href,
    };

    setLoading(true);
    const username = data?.creator?.username; // Extract the username

    ApiService.post(`${url}/supporter/jollof/${username}/buy`, payload, {})
      .then(({ data }) => {
        console.log(data);
        window.location.replace(data?.data?.gatewayData?.authorization_url);
        setLoading(false);
        setVerifying(true);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });

    //?trxref=C0NFnlZNPF6kfDRy&reference=C0NFnlZNPF6kfDRy
  };

  const onVerifyPayment = () => {
    ApiService.get(`${url}/payment/verify/${reference}`)
      .then(({ data }) => {
        console.log("verified data", data);
        setVerifying(false);
        setIsVerified(true);
        setModalVisible(true);
        setVerifiedData(data);
      })
      .catch((error) => {
        setVerifying(false);
        console.log({ error });
      });
  };

  const clearQueryParams = () => {
    const path = location.pathname;
    navigate(path, { replace: true });
    setModalVisible(false);
    setVerifying(false);
  };

  useEffect(() => {
    if (!verifying && isVerified) {
      setShowFireworks(true);

      const timer = setTimeout(() => {
        setShowFireworks(false); // Close the modal after fireworks finish
      }, 5000); // Show fireworks for 5 seconds

      return () => clearTimeout(timer); // Cleanup function
    }
  }, [verifying, isVerified]);

  return (
    <section className="w-full sm:w-full md:w-[570px] border-[1px] border-gray-300 bg-white rounded-2xl">
      <header className="px-4 py-4 flex flex-col md:flex-row justify-between items-center border-b-[1px] border-[#F5F5F5]">
        <span className="font-gt-su-bd text-base">
          Buy{" "}
          <span className="text-primary-600 ">{data?.creator?.username}</span>{" "}
          {data?.jollofPage?.plateName}
        </span>
        <div className="flex mt-2 md:mt-0 items-center px-5 py-2 gap-2 bg-primary-100 rounded-full">
          {data?.plateName === "jollof" && <JollofPlate />}
          <span className="font-gt-w-regular text-sm text-primary-600">
            One plate of {data?.jollofPage?.plateName} is{" "}
          </span>
          <CurrencyFormat
            prefix={getCurrency(
              data?.jollofPage?.platePricing[0]?.currency ?? "NGN"
            )}
            value={data?.jollofPage?.platePricing[0]?.amount ?? 0}
            thousandSeparator={true}
            displayType="text"
            className="font-gt-w-bold text-sm text-primary-600"
          />
        </div>
      </header>

      <form className="px-4 md:px-[18px] py-4">
        <header className="flex items-center justify-between">
          <span className="font-gt-w-regular text-base text-neutral-800">
            {size <= 540
              ? "Support count"
              : "How many plates would you like to buy?"}
          </span>
          <div className="flex items-center gap-3">
            <div className="flex items-center gap-2">
              <span
                className="cursor-pointer"
                onClick={() => handleChangeValue("decrement")}
              >
                <MinusIcon />
              </span>
              <input
                type="number"
                value={number}
                onChange={onHandleNumberChange}
                className="font-gt-w-medium text-center rounded-sm text-sm w-12 h-10 border-[1px] focus:outline-none outline-none bg-[#F5F5F5] border-gray-300"
              />
              <span
                className="cursor-pointer"
                onClick={() => handleChangeValue("increment")}
              >
                <PlusIcon />
              </span>
            </div>
            <div className="bg-primary-100 py-[10px] px-4 md:px-[37px] rounded-full border-[1px] border-primary-300">
              <CurrencyFormat
                prefix={getCurrency(data?.jollofPage?.currency ?? "NGN")}
                value={
                  (data?.jollofPage?.platePricing[0]?.amount ?? 0) *
                  Number(number)
                }
                thousandSeparator={true}
                displayType="text"
                className="font-gt-w-bold text-sm text-primary-600"
              />
            </div>
          </div>
        </header>
        <main className="w-full text-center">
          <div className="flex flex-col md:flex-row justify-between items-center gap-1 mb-3 mt-3">
            <div className="mb-2 md:mb-0 flex-1 w-full">
              <AppInput
                label="your name"
                placeholder="your name"
                name="name"
                type="text"
                height={"42px"}
                register={register}
                errors={errors}
                onChange={onHandleChange}
                noLabel={true}
              />
            </div>
            <div className=" flex-1 w-full">
              <AppInput
                label="your email"
                placeholder="johndoe@gmail.com"
                name="email"
                type="text"
                height={"42px"}
                register={register}
                errors={errors}
                onChange={onHandleChange}
                noLabel={true}
              />
            </div>
          </div>
          <div className="mb-2">
            <TextArea
              label="your name"
              placeholder={`Send nice message to ${data?.creator?.name}`}
              name="message"
              register={register}
              errors={errors}
              noLabel={true}
              onChange={onHandleChange}
              height="h-20"
            />
          </div>
          <div className="flex items-center gap-1">
            <input
              type="checkbox"
              checked={messagePrivate}
              onChange={handleCheckBox}
            />
            <span className="font-gt-w-regular text-sm text-neutral-800">
              Make this message private
            </span>
          </div>
          <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-3">
            <Link
              to={`${location?.pathname}/membership`}
              className="w-full rounded-full flex justify-center items-center  h-12 font-gt-w-medium text-base text-primary-600 bg-primary-100"
            >
              Become a monthly supporter
            </Link>
            <AppButton
              title={`Buy me ${number} ${data?.jollofPage?.plateName}`}
              isLoading={loading}
              onClick={handleSubmit(onHandleSend)}
              size={"medium"}
              intent={"primary"}
            />
          </div>
          <span className="text-xs font-gt-w-regular text-center text-neutral-600">
            Payment secured by Paystack. You’ll be taken to a thank you page
            after the payment.
          </span>
        </main>
      </form>
      {modalVisible ? (
        <Modal
          onClose={
            isVerified
              ? () => clearQueryParams()
              : () => console.log("cant close")
          }
        >
          {showFireworks && <Fireworks autorun={{ speed: 1 }} />}
          <div className="p-6">
            {verifying && !isVerified ? (
              <div className="mt-3 text-center sm:mt-5">
                <Loader color="orange" />
                <h3 className="text-base font-gt-w-medium leading-6 text-gray-900">
                  Verifying your payment
                </h3>
                <div className="mt-2">
                  <p className="text-sm font-gt-w-regular text-neutral-700">
                    Please wait, we are trying to verify your support payment to{" "}
                    {data?.creator?.username}
                  </p>
                </div>
              </div>
            ) : (
              <div className="flex w-full flex-col justify-center items-center">
                <span className="w-16 h-16 flex items-center justify-center bg-[#EAF6E9] rounded-full">
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24ZM32.0607 17.9393C32.6464 18.5251 32.6464 19.4749 32.0607 20.0607L22.0607 30.0607C21.4749 30.6464 20.5251 30.6464 19.9393 30.0607L15.9393 26.0607C15.3536 25.4749 15.3536 24.5251 15.9393 23.9393C16.5251 23.3536 17.4749 23.3536 18.0607 23.9393L21 26.8787L25.4697 22.409L29.9393 17.9393C30.5251 17.3536 31.4749 17.3536 32.0607 17.9393Z"
                      fill="#30A727"
                    />
                  </svg>
                </span>
                <h3 className="text-base mt-2 font-gt-w-medium leading-6 text-gray-900">
                  Support successful
                </h3>
                <div className="mt-0">
                  <p className="text-sm text-center font-gt-w-regular text-neutral-700">
                    You rock my g, your support payment has been confirmed to be
                    sent to {data?.creator?.username}
                  </p>
                </div>
                <span className="w-full flex justify-items-start text-sm font-gt-w-regular mt-10">
                  A message from {data?.creator?.username}
                </span>
                <div className="bg-[#F4F5F5] p-3 w-full rounded-sm mt-2">
                  <p className="text-sm font-gt-w-regular text-[#626262]">
                    {data?.jollofPage?.thankYouMessage}
                  </p>
                </div>
                <div className="w-full mt-5 text-center">
                  <h6 className="font-gt-w-medium text-base">
                    Spread the word
                  </h6>
                  <p className="font-gt-w-regular text-sm">
                    {data?.creator?.username} would love a shout on your socials
                  </p>
                  <div className="w-full mt-2 bg-[#f6f6f6] px-3 py-2 flex items-center justify-between rounded-[12px]">
                    <p className="font-regular text-[#777] font-gt-w-medium text-xs sm:text-sm md:text-base">
                      {cleanUrl}
                    </p>
                    <button
                      onClick={() => copyToClipboard(cleanUrl)}
                      className="cursor-pointer"
                    >
                      <Copy />
                    </button>
                  </div>
                  <div className="grid grid-cols-4 gap-4 mt-2">
                    <a
                      href={`https://twitter.com/intent/tweet?url=${cleanUrl}`}
                      className="w-full flex flex-col items-center"
                    >
                      <div className="w-10 h-10 rounded-full flex justify-center items-center bg-[#f6f6f6] cursor-pointer outline-none border-none mx-auto">
                        <Twitter width="24px" height="24px" />
                      </div>
                    </a>

                    <div className="w-full flex flex-col items-center">
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${cleanUrl}`}
                        className="w-10 h-10 rounded-full flex justify-center items-center bg-[#f6f6f6] cursor-pointer outline-none border-none mx-auto"
                      >
                        <Facebook width="24px" height="24px" />
                      </a>
                    </div>
                    <div className="w-full flex flex-col items-center">
                      <a
                        href={`https://telegram.me/share/url?url=${cleanUrl}`}
                        className="w-10 h-10 rounded-full flex justify-center items-center bg-[#f6f6f6] cursor-pointer outline-none border-none mx-auto"
                      >
                        <Telegram width="24px" height="24px" />
                      </a>
                    </div>

                    <div className="w-full flex flex-col items-center">
                      <a
                        href={`https://telegram.me/share/url?url=${cleanUrl}`}
                        className="w-10 h-10 rounded-full flex justify-center items-center bg-[#f6f6f6] cursor-pointer outline-none border-none mx-auto"
                      >
                        <Whatsapp width="24px" height="24px" />
                      </a>
                    </div>
                    {/* end of a session */}
                  </div>
                  {copied && (
                    <p className="text-center text-sm font-gt-w-regular text-green-500 mt-4">
                      Copied to clipboard!
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        </Modal>
      ) : null}
    </section>
  );
};

export default SupportWidget;
