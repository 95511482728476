import { useState } from "react";
import clsx from "clsx";
import {
  IGIcon,
  SnapChatIcon,
  TikTokIcon,
  TwitterIcon,
} from "../../../../../assets/icons";
import { useWindowSize } from "@uidotdev/usehooks";

export const socialLog: any = {
  instagram: <IGIcon />,
  tiktok: <TikTokIcon />,
  snapchat: <SnapChatIcon />,
  "x (twitter)": <TwitterIcon />,
};

const links: any = {
  instagram: "https://www.instagram.com",
  "x (twitter)": "https://www.x.com",
  tiktok: "https://www.tiktok.com",
  youtube: 'https://www.youtube.com'
}

const SocialWidget = ({ data }: { data: any }) => {
  const size = useWindowSize().width ?? 0;
  let linkToSocial = [];
  for(const key in data?.creator?.socialMedia){
    linkToSocial.push({type: key, username: data?.creator?.socialMedia[key]})
  }

  return (
    <section className="bg-white flex flex-col py-3 px-4 rounded-2xl w-full flex-1 border-[1px] border-gray-300">
      <div className="w-full flex items-center justify-between mb-3">
        <h6 className="font-gt-su-md text-sm text-black-100">Socials</h6>
      </div>

      <section className="grid grid-cols-2 grid-rows-2 gap-2">
        {linkToSocial?.map((datum: any) => {
          return (
            <Social size={size} type={datum?.type} username={datum?.username} />
          );
        })}
      </section>
    </section>
  );
};

export default SocialWidget;

const Social = ({
  type,
  username,
  size,
}: {
  type: string;
  username: string;
  size: number;
}) => {
  return (
    <section
      className={clsx(
        "w-20   md:w-[94p] lg:w-full md:h-[46px] flex flex-col gap-1 rounded-lg p-2",
        {
          "ig-style text-white": type === "instagram",
          "bg-[#55ACEE] text-white": type === "x (twitter)",
          "bg-[#FCD704]": type === "snapchat",
          "bg-black text-white": type === "tiktok",
        }
      )}
    >
      <header className="flex items-center justify-between">
        {socialLog[type]}

        <a href={`${links[type]}/${username}`} target="_blank" className="bg-white rounded-full flex items-center justify-center w-4 h-4">
          <svg
            width="12"
            height="12"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 18L18 6M18 6H9M18 6V15"
              stroke="#1C274C"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </a>
      </header>
      {size <= 540 ? null : (
        <span className="font-gt-w-regular text-[8px]">@{username}</span>
      )}
    </section>
  );
};
