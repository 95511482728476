import { AdvancedImage } from "@cloudinary/react";
import AssetTransform from "../utils";

/**
 *
 * @param {string} title: the title in the card
 * @param {string} body: the body of the card
 * @returns {string} imgUrl: the url of the card image
 */
const Card = ({
  title,
  body,
  imageUrl,
  imageClassnames,
  cardBg,
  textClassname,
  textWidth,
  height,
  centered = false,
  isComing = false,
  tagClassname
}: {
  title: string;
  body: string;
  imageUrl?: string;
  imageClassnames?: string;
  cardBg: string;
  textClassname?: string,
  textWidth?: string
  height?: string,
  centered?: boolean,
  isComing?: boolean,
  tagClassname?: string,
}) => {

  return (
    <section className={`relative rounded-[32px] lg:rounded-[48px] flex flex-col ${centered? "items-center" : "" } lg:flex-1 p-6 lg:p-12 ${cardBg} overflow-hidden ${height}`}>
      {isComing && <span className={`regular-font ${tagClassname} text-xs lg:text-base py-2 px-2 lg:px-5 flex justify-center items-center w-24 lg:w-40 rounded-full mb-4 lg:mb-6`}>Coming soon</span> }
      <h1 className="title-font-bd text-xl md:text-4xl lg:text-[40px] lg:leading-[48px] xl:leading-[48px] 2xl:leading-[46px]">{title}</h1>
      <p className={`regular-font text-sm md:text-xl lg:text-xl pt-4 ${textClassname} ${textWidth}`}>{body}</p>
      <AdvancedImage
        cldImg={AssetTransform(imageUrl ?? "")}
        className={imageClassnames}
      />
    </section>
  );
};

export default Card;


