import Footer from "./components/footer";
import Navbar from "./components/navbar";

const Terms = () => {
  return (
    <section>
      <section className="bg-style bg-white flex flex-col items-center justify-center pt-8 px-4 pb-16  lg:pt-10 lg:px-[70px]">
        <Navbar />

        <section className=" py-20">
          <h1 className="text-3xl lg:text-5xl text-black text-center title-font-bd">
            Terms of use
          </h1>
        </section>
      </section>
      <section className="py-4">
        <div className="bg-white pt-8 lg:px-28 lg:mb-32 2xl:px-96">
          <section className="max-w-[760px] mx-auto">
            <h1 className="text-xl font-bold">1. Introduction</h1>
            <p className="text-base mt-3 regular-font">
              Welcome to Buymejollof, this page explains our terms of use. When
              you use Buymejollof, you’re agreeing to all the rules on this
              page. Some of them need to be expressed in legal language, but
              we’ve done our best to offer you clear and simple explanations of
              what everything means.
            </p>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">
                2. About Creating an Account
              </h1>
              <p className="text-base regular-font mt-3">
                To sign up for a Buymejollof account, you need to be 18 or over.
                You’re responsible for your account and all the activity on it.
                You can browse Buymejollof without registering for an account.
                But to use some of our features, you’ll need to register, choose
                a username, and set a password. When you do that, the
                information you give us has to be accurate and complete. Don’t
                impersonate anyone else or choose names that are offensive or
                that violate anyone’s rights. If you don’t follow these rules,
                we may cancel your account.
              </p>
              <p className="text-base regular-font mt-3">
                You’re responsible for all the activity on your account, and for
                keeping your password confidential. If you find out that someone
                has used your account without your permission, you should report
                it to support@buymejollof.com.
              </p>
              <p className="text-base regular-font mt-3">
                To sign up for an account, you need to be at least 18 years old,
                or old enough to form a binding contract where you live. If
                necessary, we may ask you for proof of age.
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">
                3. Things that are big no-no’s
              </h1>
              <p className="text-base regular-font mt-3">
                Thousands of people use Buymejollof. We expect all of you to
                behave responsibly and help keep this a nice place. If you want
                be a part of this don’t do any of these things on our site:
              </p>
              <p className="text-base regular-font mt-3">
                - Don’t break the law. Don’t take any action that infringes or
                violates other people’s rights, violates the law, or breaches
                any contract or legal duty you have toward anyone.
              </p>
              <p className="text-base regular-font mt-3">
                - Don’t lie to people. Don’t post information you know is false,
                misleading, or inaccurate. Don’t do anything deceptive or
                fraudulent.
              </p>
              <p className="text-base regular-font mt-3">
                - Don’t offer prohibited items. Don’t offer any rewards that are
                illegal, violate any of Buymejollof's policies, rules, or
                guidelines, or violate any applicable law, statute, ordinance,
                or regulation.
              </p>
              <p className="text-base regular-font mt-3">
                - Don’t victimize anyone. Don’t do anything threatening,
                abusive, harassing, defamatory, libelous, tortious, obscene,
                profane, or invasive of another person’s privacy.
              </p>
              <p className="text-base regular-font mt-3">
                - Don’t spam. Don’t distribute unsolicited or unauthorized
                advertising or promotional material, or any junk mail, spam, or
                chain letters. Don’t run mail lists, listservs, or any kind of
                auto-responder or spam on or through the Site.
              </p>
              <p className="text-base regular-font mt-3">
                - Don’t abuse other users’ personal information. When you use
                Buymejollof — and especially if you create a successful page —
                you may receive information about other users, including things
                like their names, email addresses, and postal addresses. This
                information is provided for the purpose of participating in
                Buymejollof: don’t use it for other purposes, and don’t abuse
                it.
              </p>
              <p className="text-base regular-font mt-3">
                - Don't post anything that is - is unlawful, threatening,
                abusive, harassing, defamatory, libelous, deceptive, fraudulent,
                invasive of another’s privacy, tortious, obscene, vulgar,
                pornographic, offensive, profane, contains or depicts nudity,
                contains or depicts sexual activity, or is otherwise
                inappropriate as determined by us in our sole discretion.
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">
                4. Things we don’t do and are not responsible for
              </h1>
              <p className="text-base regular-font mt-3">
                Buymejollof is not and should not be held liable for any damages
                or losses related to your use of the Services. We don’t become
                involved in disputes between users, or between users and any
                third party relating to the use of the Services. We don’t
                oversee the performance or punctuality of pages and contents,
                and we don’t endorse any content users submit to the Site. When
                you use the Services, you release Buymejollof from claims,
                damages, and demands of every kind — known or unknown, suspected
                or unsuspected, disclosed or undisclosed — arising out of or in
                any way related to such disputes and the Services. All content
                you access through the Services is at your own risk. You’re
                solely responsible for any resulting damage or loss to any
                party.
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">4. About our fees</h1>
              <p className="text-base regular-font mt-3">
                Creating an account on Buymejollof is free. If you create a page
                that is successfully supported, we (and our payment partners)
                collect fees. Our partners’ fees may vary slightly based on your
                location. Each payment provider is its own company, and
                Buymejollof isn’t responsible for its performance. You’re
                responsible for paying any additional fees or taxes associated
                with your use of Buymejollof.
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">
                5. About other websites and links
              </h1>
              <p className="text-base regular-font mt-3">
                Buymejollof may contain links to other websites. (For instance,
                user websites pages, user profiles, and comments that may link
                to other sites.) When you access third-party websites, you do so
                at your own risk. We don’t control or endorse those sites.
              </p>
              <p className="text-base regular-font mt-3">
                Buymejollof is partnered with Flutterwave and Paystack for
                payment processing. When you support or create a page, you're
                also agreeing to the payment processor's terms of service.
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">
                6. Our Intellectual Property
              </h1>
              <p className="text-base regular-font mt-3">
                - Buymejollof’s services, content and Marks, are legally
                protected in a number of ways, including pursuant to copyright,
                trademark, service marks, patent, trade secrets, and other
                Nigeria. and international intellectual-property laws.
              </p>

              <p className="text-base regular-font mt-3">
                - You agree to respect all copyright and other legal notices,
                information, and restrictions contained in any Buymejollof
                Content, Services, or Marks accessed through the Site or the
                Services.
              </p>
              <p className="text-base regular-font mt-3">
                - You agree not to change, translate, or otherwise create
                derivative works of the Services.
              </p>
              <p className="text-base regular-font mt-3">
                - Buymejollof grants you a limited license (that is temporary,
                non-exclusive, non-sub-licensable, and non-Transferable) to
                access and use User Content and Buymejollof’s Content solely for
                use of the Services in accordance with these Terms.
              </p>
              <p className="text-base regular-font mt-3">
                - You may not reproduce, redistribute, transmit, assign, sell,
                broadcast, rent, share, lend, modify, adapt, edit, create
                derivative works of, license, or otherwise transfer or use any
                User Content or Buymejollof Content unless We give you express
                written permission to do so.
              </p>
              <p className="text-base regular-font mt-3">
                - We reserve the right to revoke this limited license to access
                and use User Content and Buymejollof Content at any time and in
                our sole discretion.
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">
                7. Your intellectual property
              </h1>
              <p className="text-base regular-font mt-3">
                - Any royalties or licensing on your Content are your
                responsibility. You will pay all royalties and other amounts
                owed to any person or entity based on your Content, or on
                Buymejollof’s hosting of that Content.
              </p>

              <p className="text-base regular-font mt-3">
                - You’re responsible for the stuff you post. All information
                submitted to the Site, whether publicly posted or privately
                transmitted, is the sole responsibility of the person from whom
                that content originated.
              </p>
              <p className="text-base regular-font mt-3">
                - We’re not responsible for mistakes in your content.
                Buymejollof will not be liable for any errors or omissions in
                any content.
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">8. Account Deletion</h1>
              <p className="text-base regular-font mt-3">
                You may stop using our Service, by contacting Buymejollof at
                support@buymejollof.com, and requesting account deletion. We may
                retain certain information as required by law or as necessary
                for our legitimate business purposes. All provisions of this
                agreement survive termination of an account, including our
                rights regarding any content you’ve already submitted to the
                Site. (For instance, if you’ve launched a page, deleting your
                account will not automatically remove the page from the Site.)
                If you have signed up for a membership, you can find information
                about your cancellation rights on our site. You will need to
                cancel your subscription in accordance with these cancellation
                rights to stop your recurring payments for the relevant
                subscription. Merely deleting your account without canceling
                your subscription will not stop these payments.
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">9. About Indemnity</h1>
              <p className="text-base regular-font mt-3">
                You agree to defend, indemnify and hold harmless Buymejollof,
                Our subsidiaries and affiliated companies, and Our officers,
                directors, employees, partners, contractors, representatives,
                agents, and third party providers from and against any and all
                claims, causes of action, damages, obligations, losses,
                liabilities, costs or debt, and expenses (including reasonable
                attorneys' fees and costs) and all amounts paid in settlement
                arising from or relating to, breach of these Terms or violation
                of any applicable laws. We reserve the right, in Our sole
                discretion and at Our own expense, to assume the exclusive
                defense and control of any matter for which you have agreed to
                indemnify us and you agree to assist and cooperate with us as
                reasonably required in the defense or settlement of any such
                matters.
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">
                10. About dispute resolution
              </h1>
              <p className="text-base regular-font mt-3">
                We at Buymejollof encourage you to contact us if you’re having
                an issue, before resorting to the courts. In the unfortunate
                situation where legal action does arise, these Terms (and all
                other rules, policies, or guidelines incorporated by reference)
                will be governed by and construed in accordance with the laws of
                the United States, without giving effect to any principles of
                conflicts of law, and without application of the Uniform
                Computer Information Transaction Act or the United Nations
                Convention of Controls for International Sale of Goods.
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">11. About copyright</h1>
              <p className="text-base regular-font mt-3">
                The Digital Millennium Copyright Act lays out a system of legal
                requirements for dealing with allegations of copyright
                infringement. Buymejollof complies with the DMCA, and we respond
                to notices of alleged infringement if they comply with the law
                and the requirements set forth in our Copyright Policy. We
                reserve the right to delete or disable content alleged to be
                infringing, and to terminate accounts for repeat infringers. (We
                do this when appropriate and at our sole discretion.)
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">
                12. Agreement Between You and Us
              </h1>
              <p className="text-base regular-font mt-3">
                These Terms are the entire agreement between You and Buymejollof
                with respect to the Services. They supersede all other
                communications and proposals (whether oral, written, or
                electronic) between you and Buymejollof with respect to the
                Services and govern our relationship. If any provision of these
                Terms are deemed invalid by a court of competent jurisdiction,
                the invalidity of such provision shall not affect the validity
                of the remaining provisions of this Agreement, which shall
                remain in full force and effect. Buymejollof's failure to assert
                any right or provision under this Agreement shall not constitute
                a waiver of such right or provision.
              </p>
            </div>

            <div className="w-full mt-10">
              <h1 className="text-xl title-font-bd">13. Miscellaneous</h1>
              <p className="text-base regular-font mt-3">
                We may modify or discontinue the Services at any time, in our
                sole discretion. You agree that, except as otherwise expressly
                provided in these Terms, there shall be no third-party
                beneficiaries to these Terms. No waiver of any provision of
                these Terms shall be deemed a further or continuing waiver of
                such term or any other term, and Buymejollof’s failure to assert
                any right or provision under these Terms shall not constitute a
                waiver of such right or provision. You agree that regardless of
                any statute or law to the contrary, any claim arising out of or
                related to the Services must commence within one (1) year after
                the cause of action accrues. Otherwise, such cause of action is
                permanently barred.
              </p>
            </div>

            <div className="w-full mt-8">
              <h1 className="text-xl font-bold title-font-bd">Contact Us</h1>
              <p className="text-base font-normal regular-font mt-3">
                Please feel free to contact us if you have any questions about
                Buymejollof’s Privacy Policy or the information practices. You
                may contact us as follows: You may send an email to
                support@buymejollof.com, or send mail to: Buymejollof
                <a href="mailto:support@buymejollof.com" className="underline">
                  support@buymejollof.com
                </a>
              </p>
              <p className="mt-3">Write to us at:</p>
              <p className="regular-font">
                6 Ebun Adegbamigbe avenue, Seaside Estate Nigeria
              </p>{" "}
              <p className="regular-font">Badore, Ajah, Lagos. Nigeria</p>{" "}
              <p className="mt-3">Call us at:</p>
              <p>+2347066560656</p>
            </div>
          </section>
        </div>
      </section>
      <section className="pt-10">
        <Footer />
      </section>
    </section>
  );
};

export default Terms;
