import { useState } from "react";
import { ArrowRight } from "../assets";
import ReUseModal from "./Modal";
import axios from "axios";
import Spinner from "./Spinner";

const Reserve = () => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const saveContact = async () => {
    if(!email) return;
    setLoading(true);
    try {
      const apiUrl =
        "https://api.fitness.realjay.dev/app-client/email/add-contact-to-list"; // Replace with your actual API endpoint
      const payload = {
        firstName: "Awesome",
        lastName: "Buymejollof supporter",
        email,
        listName: "Buymejollof",
      };

      const config = {
        headers: {
          Authorization: `Bearer bt47tsMo4wfFt6HVkzAD0VSBwye2O6pVYQBH0DVNHg3tuZFa2bPXrkOVyheoohEd`,
        },
      };
      await axios.post(apiUrl, payload, config);
      setLoading(false);
      setOpen(true);
    } catch (error) {
      alert("Error saving contact");
      setLoading(false);
    }
  };
  return (
    <>
      <div className="md:p-2 lg:p-2 mt-4 md:mt-10 lg:mt-10 w-full md:border-[1px] md:border-solid md:border-neutral-500 md:rounded-full  lg:border-[1px] lg:border-solid lg:border-neutral-500 lg:rounded-full flex flex-col gap-3 md:flex-row lg:flex-row items-center justify-between lg:h-auto lg:w-[679px] m-auto">
        <input
          type="text"
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
          placeholder="Enter your favourite email address"
          className="text-sm focus:outline-none regular-font text-neutral-500 rounded-full md:rounded-none  lg:rounded-none  md:text-xl lg:text-xl p-3 lg:p-0 md:ml-6 lg:ml-6 bg-transparent w-full md:w-80 lg:w-80  md:border-none border-[1px] border-solid border-neutral-500  lg:border-[0px] lg:border-solid lg:border-neutral-500"
        />
        <button
          disabled={loading}
          className="bg-mainYellow py-4 px-8 w-full md:w-auto lg:w-auto rounded-full lg:min-w-[250px] flex justify-center "
          onClick={saveContact}
        >
          {loading ? (
            <Spinner />
          ) : (
            <span className="bold-font flex justify-center items-center gap-2">
              Reserve your spot
              <ArrowRight />
            </span>
          )}
        </button>
      </div>
      <ReUseModal open={open} setOpen={setOpen} />
    </>
  );
};

export default Reserve;
