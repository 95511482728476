export const structure = {
  widgets: {
    about: {
      type: "about",
      data: {
        fullname: "",
        profilePhoto: "",
        about: "",
      },
    },
    intro: {
      type: "intro",
      data: {
        videoUrl: "",
      },
    },
    support: {
      type: "support",
      "plateName": "beans",
      "platePricing": [
          {
              "amount": 5000,
              "currency": "NGN"
          }
      ],
      plans: [],
      data: {
        amount: 5000,
        thankYouMessage: "",
        hasMembership: true,
        memberships: [],
        supportType: {
          type: "jollof",
          icon: "",
        },
      },
    },
    wishlist: {
      type: "wishlist",
      data: {
        title: "Create a wishlist",
        wishlist: [],
      },
    },
  },

  theme: {
    pattern: null, //this would be a url
    color: "default",
    typeface: "arial",
  },
};

export const themeStructure = [
  {
    id: 1,
    name: "Minimal",
    colors: [{ hex: "#fff", colorName: "Deep green" }],
    typeface: ["arial", "time new roman", "default"],
  },
  {
    id: 2,
    name: "Illustration",
    patterns: ["link to pattern url"],
    typeface: ["arial", "time new roman", "default"],
  },
  {
    id: 2,
    name: "abstract",
    patterns: ["link to pattern url"],
    colors: [{ hex: "#fff", colorName: "Deep green" }],
    typeface: ["arial", "time new roman", "default"],
  },
];
