import { PayloadAction } from "@reduxjs/toolkit";
import { IInitialState } from "../types";

export const initialStateObj = {
  loading: false,
  success: false,
  error: { status: null, message: "" },
  data: {},
};

export const clearStateFn = (state: any, initialState: IInitialState) => {
  return Object.assign(state, initialState);
};

/**
 *
 * @param state
 * @param action
 * @param initialState
 * @returns
 */
export const resetBlockFn = (
  state: any,
  action: PayloadAction<{ blockType: any }>,
  initialState: any
) => {
  let v =
    action.payload.blockType === null
      ? { ...state, loading: false, success: false }
      : {
          ...state,
          [action.payload.blockType]: {
            ...initialState[action.payload.blockType],
          },
        };
  console.log({ v });
  return v;
};

export const handleFulfilled = (
  state: any,
  initialStateKey: string,
  { payload }: { payload: any }
) => {
  if (initialStateKey === null) {
    state.loading = false;
    state.success = true;
    state.data = payload?.data;
  } else if (state.hasOwnProperty(initialStateKey)) {
    state[initialStateKey].loading = false;
    state[initialStateKey].success = true;
    state[initialStateKey].data = {
      ...payload.data,
    };
  }
};
