import Footer from "./components/footer";
import Navbar from "./components/navbar";

const Refund = () => {
  return (
    <section>
      <section className="bg-style bg-white flex flex-col items-center justify-center pt-8 px-4 pb-16  lg:pt-10 lg:px-[70px]">
        <Navbar />

        <section className=" py-20">
          <h1 className="text-3xl lg:text-5xl text-black text-center title-font-bd">
            Refund Policy
          </h1>
        </section>
      </section>
      <section className="py-4">
        <div className="bg-white pt-8 lg:px-28 lg:mb-32 2xl:px-96">
          <section className="max-w-[760px] mx-auto">
            <span className="text-base regular-font">
              Last updated May 29th, 2024
            </span>

            <h1 className="text-xl font-bold mt-4"> Returns and Refunds. </h1>
            <p className="text-base mt-3 regular-font">
              You (the Creator) must accept (and Buymejollof will accept on your
              behalf) all returns from end users within 30 days of the Product
              Order for Products with a defect that is covered by a Supplier
              warranty (“Supplier Defective Product”). Buymejollof will refund
              (which would deducted from the creator balance) to the end user
              the purchase price of the Supplier Defective Products and will be
              responsible for all costs in connection with returns of Supplier
              Defective Products. For the avoidance of doubt, Buymejollof does
              not accept returns of, or issue refunds for, Products that are not
              defective. Additionally, you may choose to accept returns from,
              and issue refunds to, your end users for any reason at your sole
              discretion. You will be fully responsible for and will bear all
              costs in connection with any other refund that you provide to any
              end user.
            </p>
            <h1 className="text-xl font-bold mt-10"> Questions.</h1>
            <p className="text-base mt-3 regular-font">
              If you have any questions concerning our return policy, please
              contact us at:
            </p>
            <p className="text-base regular-font">
              <a href="tel:+2347066560656"> +2347066560656</a>
            </p>
            <p className="text-base regular-font">
              <a href="mailto:support@buymejollof.com">
                support@buymejollof.com
              </a>
            </p>
          </section>
        </div>
      </section>
    </section>
  );
};

export default Refund;
