import React, { Fragment, MouseEventHandler } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CloseIcon,
} from "../../assets/icons";



export default function Modal({
  onClose,
  children,
}: {
  onClose: Function;
  children?: React.ReactNode;
  onHandleDelete?: MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => onClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="relative flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg  text-left  transition-all w-[420px]  sm:max-w-[420px]">
                <div className="w-full flex justify-end">
                  <span
                    onClick={() => onClose()}
                    className="text-lg text-red-400 right-0 bg-white rounded-full flex p-2 items-center justify-center cursor-pointer"
                  >
                    <CloseIcon />
                  </span>
                </div>
                <div className="w-full bg-white rounded-lg shadow-xl sm:my-6">
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
