import { Cloudinary } from "@cloudinary/url-gen";
import Faq from "./components/Faq";
import Footer from "./components/footer";
import HowItWorks from "./components/HowItWorks";

import SuperFans from "./components/SuperFans";
import Masthead from "./components/Masthead";
import Delight from "./components/Delight";
import Support from "./components/Support";
import { AdvancedImage } from "@cloudinary/react";
import AssetTransform from "./utils";
import { ArrowRight } from "./assets";
export const cld = new Cloudinary({ cloud: { cloudName: "groomlyhq" } });

const App = () => {
  return (
    <div className="">
      <Masthead />
      <SuperFans />

      <Delight />

      <Support />
      <Donations />
      <HowItWorks />
      <Faq />
      <Footer />
    </div>
  );
};

const Donations = () => {
  return (
    <section className="px-4 bg-white pt-8 md:pt-28 lg:pt-28 lg:px-28 lg:mb-32 2xl:px-96">
      <div className="mt-5 flex-col flex md:flex-row lg:flex-row justify-between gap-10">
        <div className="w-full md:w-96 lg:w-96">
          <AdvancedImage
            cldImg={AssetTransform(
              "v1693876512/marketing-website/donations-min_m7nazk.png"
            )}
            alt=""
          />
        </div>
        <div className="w-full lg:w-3/6">
          <h1 className="text-xl lg:text-5xl text-mainAsh title-font-bd lg:leading-[60px]">
            Easily accept donations for your enterprises
          </h1>
          <p className="regular-font text-sm lg:text-xl pt-4 pb-10 lg:py-10">
            Social good just got easier! Seamlessly collect donations from your
            supporters from anywhere in the world right from your dashboard.
          </p>
          <a
            href="#reserve"
            className="bg-mainYellow py-4 px-8 rounded-full bold-font flex items-center gap-2 w-60"
          >
            Reserve your spot <ArrowRight />
          </a>
        </div>
      </div>
    </section>
  );
};

export default App;
