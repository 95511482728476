import { CloudinaryImage } from "@cloudinary/url-gen";
import { quality } from "@cloudinary/url-gen/actions/delivery";
import { toast } from "sonner";

const AssetTransform = (imgName: string) => {
  return new CloudinaryImage(imgName, { cloudName: "groomlyhq" }).delivery(
    quality(60)
  );
};

export default AssetTransform;

export const getCurrency = (currency: string) => {
  switch (currency) {
    case "USD":
      return "$";
    case "NGN":
      return "₦";
    case "KES":
      return "KSh";
    case "ZMW":
      return "ZK";
    case "GHS":
      return "₵";
    case "EGP":
      return "£";
    case "ZAR":
      return "R";
    default:
      // Default case for unknown currencies
      return currency;
  }
};

export const getCountries = (prop: string) => {
  switch (prop) {
    case "NGN":
      return "Nigeria";
    case "KES":
      return "Kenya";
    case "ZM":
      return "Zambia";
    case "GHS":
      return "Ghana";
    case "EGP":
      return "Egypt";
    case "ZAR":
      return "South Africa";
    default:
      return prop;
  }
};

export const getSimplifiedError = (error: any) => {
  if (!error.response) {
    toast.error(
      "Something went wrong, check your internet and please try again"
    );
    return "Something went wrong, check your internet and please try again";
  }
  if (error.response?.status === 500) {
    toast.error("Sorry an unexpected error occurred.");
    return "Sorry an unexpected error occurred.";
  }
  if (error.response.status === 404) {
    toast.error(error.response.data.error);
    if (error.response.data.error === "user not found") {
      setTimeout(() => {
        window.location.replace("/register");
      }, 1000);
    }
    return error.response.data.error;
  } else if (error.response.status === 400) {
    toast.error(error.response.data.message);
    return "Token has expired, please log in";
  } else if (error.response.status === 401) {
    if (error.response.data.message === "Invalid credentials") {
      toast.error("Your email or password is incorrect");
    }
    toast.error(error.response.data.message || error.response.data.error);
    return error.response.data.message;
  } else {
    toast.error(error.response.data.error);
    return error.response.data.error;
  }
};

//https://res.cloudinary.com/groomlyhq/image/upload/v1693644233/marketing-website/lifestyle_creator_1_-min_aztrfo.png

export const formatAsCurrency = (value: string): string => {
  const formattedValue = `${value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  return formattedValue;
};

export const obfuscateEmail = (email: string): string => {
  if (email === "" || email === null) return "";
  const [username, domain] = email.split("@");
  const obfuscatedUsername = `${username[0]}***${
    username[username.length - 1]
  }`;
  const obfuscatedDomainPart =
    domain.substring(0, 1) + "..." + domain.substring(domain.indexOf(".") + 1);
  return `${obfuscatedUsername}@${obfuscatedDomainPart}`;
};

export const generateRandomString = () => {
  const characters =
    "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const length = 8;
  let randomString = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters[randomIndex];
  }

  return randomString;
};
