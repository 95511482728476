import { Logo } from "../assets";

export default function Navbar() {
  return (
    <nav className="bg-black text-white z-10 flex items-center justify-center md:justify-between pl-4 pr-4 lg:pr-0 lg:pl-14 rounded-full py-4 w-full">
      <Logo />

      {/* <span className="flex lg:hidden">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="4" y="4" width="7" height="7" rx="1.5" fill="#fff" />
          <path
            opacity="0.3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 5.5C13 4.67157 13.6716 4 14.5 4H18.5C19.3284 4 20 4.67157 20 5.5V9.5C20 10.3284 19.3284 11 18.5 11H14.5C13.6716 11 13 10.3284 13 9.5V5.5ZM4 14.5C4 13.6716 4.67157 13 5.5 13H9.5C10.3284 13 11 13.6716 11 14.5V18.5C11 19.3284 10.3284 20 9.5 20H5.5C4.67157 20 4 19.3284 4 18.5V14.5ZM14.5 13C13.6716 13 13 13.6716 13 14.5V18.5C13 19.3284 13.6716 20 14.5 20H18.5C19.3284 20 20 19.3284 20 18.5V14.5C20 13.6716 19.3284 13 18.5 13H14.5Z"
            fill="#fff"
          />
        </svg>
      </span> */}

      <div className="hidden lg:flex items-center lg:gap-8 pr-4">
        <a href="#about" className="text-base regular-font">
          About
        </a>
        <a href="#how-it-works" className="text-base regular-font">
          How it works
        </a>
        <a href="#features" className="text-base regular-font">
          Features
        </a>
        <a href="#faq" className="text-base regular-font">
          Faq
        </a>
        <a
          href="#masthead"
          className="bg-mainYellow text-mainAsh px-5 py-4 flex justify-center items-center rounded-3xl bold-font"
        >
          Reserve my spot
        </a>
      </div>
    </nav>
  );
}
