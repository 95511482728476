import * as Yup from "yup";

export const loginSchema = Yup.object({
  email: Yup.string()
    .email("Is this the email you'd want brands to reach you on? really? 🤔")
    .required(
      "Is this the email you'd want brands to reach you on? really? 🤔"
    ),
  password: Yup.string()
    .min(6, "Password should be longer than 6 characters")
    .required("Required"),
});

export const registerSchema = Yup.object({
  name: Yup.string()
    .min(6, "That's a short full name, don't your think?")
    .required("Required"),
  email: Yup.string()
    .email("Is this the email you'd want brands to reach you on? really? 🤔")
    .required(
      "Is this the email you'd want brands to reach you on? really? 🤔"
    ),
  password: Yup.string()
    .min(6, "Password should be longer than 6 characters")
    .required("Required"),
});

export const resetPasswordSchema = Yup.object({
  email: Yup.string()
    .email("Not a valid email")
    .required("Please enter your email address"),
});

export const passwordSchema = Yup.object({
  password: Yup.string()
    .min(6, "Password should be longer than 6 characters")
    .required("Required"),
});

export const membershipSchema = Yup.object({
  name: Yup.string().required("Please enter a name for your membership"),
  amount: Yup.string().required("This is required"),
  description: Yup.string().required(
    "Please enter a description for this membership"
  ),
  welcomeNote: Yup.string().required(
    "Come on, Make your supporters feel special tell them something nice"
  ),
});

export const oneTimeSupportSchema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  email: Yup.string().required("Your email is required for payment"),
  message: Yup.string().optional(),
});

export const creator_types = [
  "Podcaster",
  "Youtuber",
  "Tiktoker",
  "Content creator",
  "Skit maker",
  "Comedian",
  "Dancer",
  "Gamer",
  "NGO",
  "Writer",
  "Designers",
];
