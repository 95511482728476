import clsx from "clsx";
import Thumb from "../../assets/images/thumbnail.png";
import { IThumbnail } from "../../types";

const Thumbnail = ({ size, url }: IThumbnail) => {
  const thumbnailClasses = clsx("rounded-full flex-shrink-0", {
    "w-6 h-6": size === "small",
    "w-[32px] h-[32px]": size === "medium",
    "w-14 h-14": size === "large",
  });

  return (
    <div className={thumbnailClasses}>
      {!url?.length || url === undefined ? (
        <img src={Thumb} alt="your thumbnail" className="w-full h-full rounded-full object-cover" />
      ) : (
        <img src={url} alt="your thumbnail" className="w-full h-full rounded-full object-cover" />
      )}
    </div>
  );
};

export default Thumbnail;
