import { CloudinaryImage } from "@cloudinary/url-gen";
import { quality } from "@cloudinary/url-gen/actions/delivery";


const AssetTransform = (imgName: string) => {
  return new CloudinaryImage(imgName, {cloudName: 'groomlyhq'}).delivery(quality(60))
};

export default AssetTransform;



//https://res.cloudinary.com/groomlyhq/image/upload/v1693644233/marketing-website/lifestyle_creator_1_-min_aztrfo.png