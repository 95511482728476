import { useState } from "react";

const Accordion = ({ datum }: { datum: any }) => {
  const [active, setActive] = useState(false);
  return (
    <div className="faq__accordion-listItem" onClick={() => setActive(!active)}>
      <header
        className={
          active
            ? "flex justify-between items-center mb-5"
            : "flex justify-between items-center"
        }
      >
        <h4 className="bold-font">{datum.title}</h4>
        {active ? (
          <span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.84416 12H17.5325"
                stroke="#3E3E3E"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        ) : (
          <span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 12H18"
                stroke="#3E3E3E"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 18V6"
                stroke="#3E3E3E"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        )}
      </header>
      <div
        className={
          active ? "faq__accordion--content !flex" : "faq__accordion--content"
        }
      >
        <p className="regular-font">{datum.body}</p>
      </div>
    </div>
  );
};

export default Accordion;
