const data = [
  {
    id: 1,
    visible: false,
    title: "What is buymejollof?",
    body: "Buymejollof is the easiest and safest way to connect and get support from fans who love your craft. You can accept gifts, create membership tiers, open an online shop, and take commissions all with a 5% platform fee.",
  },
  {
    id: 2,
    visible: false,
    title: "Who uses buymejollof?",
    body: "Anyone with an audience—content creators, influencers, artists, YouTubers, musicians, podcasters, writers, programmers, nonprofits, cosplayers, and much more. More than 500,000 creators and millions of their fans are on Buymejollof.",
  },
  {
    id: 3,
    visible: false,
    title: "How do i get paid?",
    body: "You decide when you get paid—instantly and directly into your bank account. We receive a 5% fee only when you get gifts to keep the platform running.",
  },
  {
    id: 4,
    visible: false,
    title: "How can my audience pay?",
    body: "Once you add your account details, your supporters can donate to your wallet from which you can withdraw at any time.",
  },
  {
    id: 5,
    visible: false,
    title: "Do i pay to use Buymejollof?",
    body: "You don't pay to sign up or use Buymejollof. We only ask for a 5% platform fee to help us keep running and serving all our customers. This amount is deducted only when you receive gifts from your supporters.",
  },
  {
    id: 6,
    visible: false,
    title: "Do I have complete ownership of my supporters?",
    body: "Yes, your supporters are strictly yours. We don’t email them or have any relationship with them and you can export your list any time you like.",
  },
  {
    id: 7,
    visible: false,
    title: "How is Buymejollof different from other platforms for creators?",
    body: `With Buymejollof, you get everything you need to run your creative brand or business. And you can skip paying for and stitching together a dozen services, from sending emails to charging for subscriptions. We also give your audience the most delightful experience, from the one-tap payment (like seriously, no sign-ups required) to the little touches, we’re obsessed over every detail.`,
  },
  {
    id: 8,
    visible: false,
    title: "You don't have a feature I want, now what?",
    body: "We are working hard to make Buymejollof your one-stop shop as a creator. If there is any feature you'd like to have, please send a feature request.",
  },
  {
    id: 8,
    visible: false,
    title: "How do i contact Buy me Jollof?",
    body: "You can email us at support@buymejollof.com, or leave us a message on the Intercom chat. We also respond to every feature request you send.",
  },
];

export default data;
